import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid2';
import "./admin.css";
import { faAngleRight, faArrowUp, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ProgressBar } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { apiService } from '../../services/apiService';
import { toast } from 'react-toastify';
function Jobseekerdetails() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [preview, setPreview] = useState([]);

    useEffect(() => {
        const fetchUserData = async () => {
            setLoading(true);
            try {
                const response = await apiService.getRequest(`/admin/jobseeker/${id}`);
                if (response.success === true){
                    setPreview(response.data);
                }else{
                    toast.error("Retry")
                }
            } catch (error) {
                console.error("Error fetching user data", error);
            }finally {
                setLoading(false);
            }
        };
        fetchUserData();
    }, [id]);

  return (
    <div className='container-fluid px-0'>
        <div className='content_container'>
            <Grid size={{lg:12}} container className="main_jobseeker">
                <Grid size={{lg:12}} container alignItems="center">
                    <Grid size={{md:6,xs:12}} container flexDirection="column" className="jobseekerhead mt-2 mb-4">
                        <h3>Jobseekers</h3>
                        <span><b onClick={()=>navigate("/dashboard")}>Dashboard</b> <FontAwesomeIcon className='angleright' icon={faAngleRight} /> <b onClick={()=>navigate("/user/jobseekers")}>Jobseekers</b> <FontAwesomeIcon className='angleright' icon={faAngleRight} /> {preview.name}</span>
                    </Grid>
                    <Grid size={{md:6,xs:12}} container justifyContent="flex-end" className="my-2">
                        <button className='back_buttonminus' onClick={() => navigate(-1)}>Back</button>
                        <button className='downloadformbtn'>Task<FontAwesomeIcon className='iconrotate' icon={faArrowUp} /></button>
                    </Grid>
                </Grid>
                <Grid size={{xs:12}} container justifyContent="space-between">
                    <Grid size={{md:5.8,sm:12,xs:12}} container justifyContent="space-between" className="mb-3">
                        <Grid size={{xs:5.6}} container alignItems="flex-start" className="grid_jobseekertsker" flexDirection="column">
                            <img className='jobdegrid1img' src={require('../../assets/to-do-list.svg').default} alt=''/>
                            <span className='jobdegrid1span'><small>Total Task</small> <text>1</text></span>
                            <span className='jobdegrid1span'><small>Done Task</small> <text>0</text></span>
                        </Grid>
                        <Grid size={{xs:5.6}} container alignItems="flex-start" className="grid_jobseekertsker" flexDirection="column">
                            <img className='jobdegrid2img' src={require('../../assets/fakeprofile.png')} alt=''/>
                            <h6 className='jobdegrid2h6'>Jobseeker 1</h6>
                            <span className='jobdegrid2span'><small>Completed:</small> <text>25%</text></span>
                            <ProgressBar now={25} className='progreesbar_grid'/>
                        </Grid>
                    </Grid>
                    <Grid size={{md:5.8,sm:12,xs:12}} container justifyContent="space-between" alignItems="center" className="gradianjobgrides mb-3">
                    {loading ? 
                    <div className='d-flex justify-content-center w-100'>
                        <img style={{ width: "100px" }} src={require('../../assets/pinkloader.gif')} alt='Loading...' />
                    </div> :<>
                        <Grid size={{sm:6,xs:12}} container flexDirection="column">
                            <small>Name</small>
                            <h5>{preview.name}</h5>
                        </Grid>
                        <Grid size={{sm:6,xs:12}} container flexDirection="column" className="align-content-lg-end">
                            <small>Registration</small>
                            <h5>{preview.created_at}</h5>
                        </Grid>
                        <Grid size={{sm:6,xs:12}} container flexDirection="column">
                            <small>Status</small>
                            <h5>{preview.status}</h5>
                        </Grid>
                        <Grid size={{sm:6,xs:12}} container className="justify-content-md-end">
                            <button onClick={() => navigate(`/user/jobseeker-profile/${preview.id}`)}>View Profile<FontAwesomeIcon className='iconrotate' icon={faArrowUp} /></button>
                        </Grid>
                        </>}
                    </Grid>
                    <Grid size={{md:5.8,sm:12,xs:12}} container justifyContent="space-between" className="endgrid_list mt-3">
                        <Grid size={{xs:12}} container alignItems="flex-start" className="endgrid_border">
                            <h5>Members</h5>
                        </Grid>
                        <Grid size={{xs:12}} container alignItems="flex-start" className="px-3">
                            <Grid size={{xs:12}} container alignItems="center" justifyContent="space-between" className="endgrid_bordercontent">
                                <img src={require('../../assets/fakeprofile.png')} alt=''/>
                                <span>
                                    <text>Member 1</text>
                                    <small>hellomember@gmail.com</small>
                                </span>
                            </Grid>
                            <Grid size={{xs:12}} container alignItems="center" justifyContent="space-between" className="endgrid_bordercontent">
                                <img src={require('../../assets/fakeprofile.png')} alt=''/>
                                <span>
                                    <text>Member 2</text>
                                    <small>hellomember@gmail.com</small>
                                </span>
                            </Grid>
                            <Grid size={{xs:12}} container alignItems="center" justifyContent="space-between" className="endgrid_bordercontent">
                                <img src={require('../../assets/fakeprofile.png')} alt=''/>
                                <span>
                                    <text>Member 3</text>
                                    <small>hellomember@gmail.com</small>
                                </span>
                            </Grid>
                            <Grid size={{xs:12}} container alignItems="center" justifyContent="space-between" className="endgrid_bordercontent">
                                <img src={require('../../assets/fakeprofile.png')} alt=''/>
                                <span>
                                    <text>Member 4</text>
                                    <small>hellomember@gmail.com</small>
                                </span>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid size={{md:5.8,sm:12,xs:12}} container justifyContent="space-between" alignItems="flex-start" className="endgrid_list mt-3">
                        <Grid size={{xs:12}} container alignItems="flex-start" className="endgrid_border">
                            <h5>Active log <small>Activity Log of this Jobseeker</small></h5>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    </div>
  )
}

export default Jobseekerdetails
