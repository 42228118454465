import axios from 'axios';

// Set up the base URL for your API
const BASE_URL = process.env.REACT_APP_BASE_URL;
// Create an instance of Axios
const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Accept': 'application/json',
  },
});

// Interceptor to attach the bearer token to every request
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token'); // Assuming token is stored in localStorage after login
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    // Check if the data is an instance of FormData
    if (config.data instanceof FormData) {
      config.headers['Content-Type'] = 'multipart/form-data';
    } else {
      config.headers['Content-Type'] = 'application/json';
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Generic method to handle GET requests
const getRequest = async (endpoint) => {
  try {
    const response = await api.get(endpoint);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Generic method to handle POST requests
const postRequest = async (endpoint, data) => {
  try {
    const response = await api.post(endpoint, data);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Generic method to handle PUT requests
const putRequest = async (endpoint, data) => {
  try {
    const response = await api.put(endpoint, data);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Generic method to handle DELETE requests
const deleteRequest = async (endpoint) => {
  try {
    const response = await api.delete(endpoint);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Generic method to handle PATCH requests
const patchRequest = async (endpoint, data) => {
  try {
    const response = await api.patch(endpoint, data);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Handle API errors
const handleApiError = (error) => {
  // Here you can manage errors, redirect to login if token is invalid, etc.
  console.error('API Error:', error.response || error.message);
  if (error.response && error.response.status === 401) {
    // Token expired or invalid, handle logout
    localStorage.removeItem('token');
    window.location.href = '/login';
  }
};

// Export all API functions
export const apiService = {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
  patchRequest,
};
