import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { apiService } from '../../services/apiService';
import { useLocation, useNavigate } from 'react-router-dom';

function KnowledgeCategory({ onClose, onCategorySelect ,onSubCategorySelect,onKbaseSelect}) {
    const navigate = useNavigate();
    const location = useLocation();
    const currentPath = location.pathname;
    const [catsloading, setCatsLoading] = useState(false);
    const [catsList, SetlistingCats] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            setCatsLoading(true);
            try {
            const response = await apiService.getRequest('knowledge-bases-with-categories-and-subcategories');
            if (response && response.success === true) {
                SetlistingCats(response.data);
            }
            }catch (error) {
                console.error('Error fetching tab data:', error);
            } finally {
                setCatsLoading(false);
            }
        };
        fetchData();
    }, []);

    const handleKbaseClick = (kBaseId) => {
        onKbaseSelect(kBaseId, "");
    };
    const handleCategoryClick = (categoryId) => {
        onCategorySelect(categoryId, "");
    };

    const handleSubCategoryClick = (subCategoryId) => {
        onSubCategorySelect(subCategoryId,"");
    };
  return (
    <div className='klgcategory_main dragging_cats'>
        <div className='d-flex w-100 justify-content-end'>
            <button className='d-md-none closecat_btn' onClick={onClose}><FontAwesomeIcon icon={faXmark} /></button>
        </div>
        <div className='d-flex flex-column'>
            <Accordion>
            {catsloading ? 
            <div className='d-flex justify-content-center w-100'>
                <img style={{ width: "100px" }} src={require('../../assets/pinkloader.gif')} alt='Loading...' />
            </div> :<>
            {catsList && catsList.length > 0 ? (
                catsList && catsList.map((catsListings,index) => (
                <Accordion.Item eventKey={index} key={catsListings.id}>
                    <Accordion.Header onClick={()=> currentPath === "/knowledge"
                                        ? handleKbaseClick(catsListings.id)
                                        : (navigate('/knowledge'), localStorage.setItem('knowledgeKbaseBackId', String(catsListings.id)))}><img src={require('../../assets/chevron-down.svg').default} alt=''/>{catsListings.name}</Accordion.Header>
                    <Accordion.Body>
                        <Accordion defaultActiveKey="0">
                            
                        {catsListings && catsListings.categories && catsListings.categories.length > 0 ? (
                            catsListings.categories && catsListings.categories.map((catsmainListings,index) => (
                            <Accordion.Item eventKey={index} key={catsmainListings.id}>
                                <Accordion.Header onClick={()=> currentPath === "/knowledge"
                                        ? handleCategoryClick(catsmainListings.id)
                                        : (navigate('/knowledge'), localStorage.setItem('knowledgeCatBackId', String(catsmainListings.id)))}><img src={require('../../assets/chevron-down.svg').default} alt=''/>{catsmainListings.name}</Accordion.Header>
                                <Accordion.Body>
                                <div className='d-flex flex-column'>
                                    {catsmainListings && catsmainListings.subcategories && catsmainListings.subcategories.length > 0 ? (
                                        catsmainListings.subcategories && catsmainListings.subcategories.map((sublistings) => (<span 
                                            key={sublistings.id} 
                                            onClick={() => 
                                                currentPath === "/knowledge"
                                                    ? handleSubCategoryClick(sublistings.id)
                                                    : (navigate('/knowledge'), localStorage.setItem('knowledgeBackId', String(sublistings.id)))
                                            }
                                        >
                                            {sublistings.name}
                                        </span>))
                                        ) : (
                                            <div className="w-100 d-flex justify-content-center">
                                                <p>No data to display</p>
                                            </div>
                                        )}
                                        
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            ))
                        ) : (
                            <div className="w-100 d-flex justify-content-center mt-4">
                                <p>No data to display</p>
                            </div>
                        )}
                        </Accordion>
                    </Accordion.Body>
                </Accordion.Item>
                ))
                ) : (
                    <div className="w-100 d-flex justify-content-center mt-4">
                        <p>No data to display</p>
                    </div>
                )}
                </>}
                </Accordion>
        </div>
    </div>
  )
}

export default KnowledgeCategory
