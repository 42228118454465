import Grid from '@mui/material/Grid2';
import React from 'react'
import "./header.css"
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
function Header() {
  const navigate = useNavigate();
  const handleReloadnavigate = () => {
    window.location.reload(); // This will reload the page
    navigate("/");
  };
  return (
    <Grid size={{lg:12}} container justifyContent="space-between" className="header_main" alignItems="center">
        <Grid size={{ xs: 4, sm: 3, md: 2, lg:1.5  }} container >
            <img onClick={handleReloadnavigate} src={require('../../assets/logo.svg').default} alt=''/>
        </Grid>
        <Grid size={{ xs: 8, sm: 6, md: 6, lg:6  }} container justifyContent="flex-end">
            <Button className='login_btn' onClick={() =>{navigate("/login")}}>Login</Button>
            <Button className='register_btn' onClick={() =>{navigate("/loginselect")}}>Register Now</Button>
        </Grid>
    </Grid>
  )
}

export default Header
