import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid2';
import "./login.css";
import { useLocation, useNavigate } from 'react-router-dom';
import { apiService } from '../../services/apiService';
function RegisterDetail3() {
    const navigate = useNavigate();
    const [relationships, setRelationships] = useState([]);
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        phone_number: '',
        relation_type: '',
    });
    const [errors, setErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState('');
    const [submitLoader, setSubmitLoader] = useState(false);

    const fetchUserHistory = async () => {
        setLoading(true)
        try {
            const response = await apiService.postRequest('auth/user/history'); // Use the appropriate GET request method
            if (response && response.success && response.data.emergency_contact) {
                setFormData(response.data.emergency_contact); // Assuming the user history data is in response.data
            } else {
                
            }
        } catch (error) {
            console.log(error)
        }finally{
            setLoading(false)
        }
    };

    // Use effect to fetch user history when navigating back
    useEffect(() => {
        // Fetch data when the component mounts or when navigating back
        if (location.state && location.state.fromNextPage) {
            fetchUserHistory(); // Fetch user history if coming from next page
        }
    }, [location.state]);

    // Handle input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        // Validate the input field on change
        let validationErrors = { ...errors };

        if (name === 'first_name') {
            if (!value.trim()) {
                validationErrors.first_name = 'First name is required';
            } else {
                delete validationErrors.first_name;
            }
        }

        if (name === 'last_name') {
            if (!value.trim()) {
                validationErrors.last_name = 'Last name is required';
            } else {
                delete validationErrors.last_name;
            }
        }

        if (name === 'relation_type') {
            if (!value) {
                validationErrors.relation_type = 'Relationship is required';
            } else {
                delete validationErrors.relation_type;
            }
        }

        setErrors(validationErrors);
    };

    // Fetch data for relationships
    useEffect(() => {
        const fetchData = async () => {
            const response = await apiService.postRequest('generalsettings-data');
            if (response.success) {
                setRelationships(response.relationships);
            }
        };

        fetchData();
    }, []);

    // Handle phone number formatting and validation
    const handlePhoneNumberChange = (e) => {
        const inputElement = e.target;
        const inputValue = inputElement.value;
        const cursorPosition = inputElement.selectionStart;

        let formattedInput = inputValue.replace(/\D/g, ''); // Remove non-digit characters

        if (formattedInput.length > 3 && formattedInput.length <= 6) {
            formattedInput = formattedInput.replace(/(\d{3})(\d+)/, '($1) $2'); // Format (123) 456
        } else if (formattedInput.length > 6) {
            formattedInput = formattedInput.replace(/(\d{3})(\d{3})(\d+)/, '($1) $2-$3'); // Format (123) 456-7890
        } else if (formattedInput.length <= 3) {
            formattedInput = `(${formattedInput}`; // Start formatting with open bracket (123
        }

        setFormData({ ...formData, phone_number: formattedInput });

        const newCursorPosition = cursorPosition + (formattedInput.length - inputValue.length);
        setTimeout(() => {
            inputElement.selectionStart = newCursorPosition;
            inputElement.selectionEnd = newCursorPosition;
        }, 0);

        // Phone number validation
        let validationErrors = { ...errors };
        if (!formattedInput) {
            validationErrors.phone_number = 'Phone number is required';
        } else if (!/^\(\d{3}\) \d{3}-\d{4}$/.test(formattedInput)) {
            validationErrors.phone_number = 'Phone number must be in the format: (123) 456-7890';
        } else {
            delete validationErrors.phone_number;
        }

        setErrors(validationErrors);
    };

    // Validate form data before submission
    const validate = () => {
        const validationErrors = {};
        if (!formData.first_name.trim()) {
            validationErrors.first_name = 'First name is required';
        }else if (formData.first_name.length < 3) {
            validationErrors.first_name = ['First name must be at least 3 characters long'];
        }
        if (!formData.last_name.trim()) {
            validationErrors.last_name = 'Last name is required';
        }
        if (!formData.phone_number.trim()) {
            validationErrors.phone_number = 'Phone number is required';
        }
        else if (!/^\(\d{3}\) \d{3}-\d{4}$/.test(formData.phone_number)) {
            validationErrors.phone_number = 'Phone number must be in the format: (123) 456-7890';
        }
        if (!formData.relation_type) {
            validationErrors.relation_type = 'Relationship is required';
        }
        return validationErrors;
    };
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevents any action when Enter is pressed
        }
    };
    // Handle form submission
    const handleSubmitStep3 = async (e) => {
        e.preventDefault();
        const validationErrors = validate();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        setSubmitLoader(true);
        try {
            const response = await apiService.postRequest('auth/register/step3', formData);
            if (response && response.success === true) {
                navigate('/referral-information');
                setErrors({});
                setErrorMessage('');
            } else {
                setErrorMessage('Registration failed. Please try again.');
                if (response.errors) {
                    setErrors(response.errors);
                }
            }
        } catch (error) {
            setErrorMessage('Registration failed. Please try again.');
        } finally {
            setSubmitLoader(false); // Stop loader
        }
    };
  return (
    <Grid size={{lg:12}} container justifyContent="center" className="registerdetailsmain">
        <Grid size={{lg:10,xs:11}} container alignItems="flex-start" justifyContent="center" className="regisdetailwhite">
            <Grid size={{lg:12}} container>
                <img onClick={() =>{navigate("/")}} src={require('../../assets/logo.svg').default} alt=''/>
                <Grid size={{lg:12,xs:12}} container justifyContent="center">
                    <Grid size={{lg:6,md:6,sm:8,xs:12}} container alignItems="center" justifyContent="space-between" className="progressspan">
                        <span className='activeprogress'>1</span>
                        <span className='activeprogress'>2</span>
                        <span className='activeprogress'>3</span>
                        <span>4</span>
                    </Grid>
                </Grid>
                {loading ? 
                    <div className='d-flex justify-content-center w-100'>
                        <img style={{ width: "100px" }} src={require('../../assets/pinkloader.gif')} alt='Loading...' />
                    </div> :
                    <form className='w-100' onSubmit={handleSubmitStep3} onKeyDown={handleKeyDown}>
                        <Grid size={{lg:12,xs:12}} container justifyContent="space-between" className="regiter_inputs">
                            <h5>Emergency Contact</h5>
                            <Grid size={{sm:5.8, xs:12}} container flexDirection="column">
                                <span>First Name</span>
                                <input type='text' maxLength="200" name="first_name" value={formData.first_name} onChange={handleInputChange} onInput={(e) => e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, '')}/>
                                {errors.first_name && <p style={{color:"Red"}}>{errors.first_name}</p>}
                            </Grid>
                            <Grid size={{sm:5.8, xs:12}} container flexDirection="column">
                                <span>Last Name</span>
                                <input type='text' name="last_name" value={formData.last_name} onChange={handleInputChange} onInput={(e) => e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, '')}/>
                                {errors.last_name && <p style={{color:"Red"}}>{errors.last_name}</p>}
                            </Grid>
                            <Grid size={{sm:5.8, xs:12}} container flexDirection="column">
                                <span>Phone Number</span>
                                <input
                                    type="text"
                                    name="phone_number"
                                    autoComplete="new-password"
                                    value={formData.phone_number}
                                    onChange={handlePhoneNumberChange}
                                    placeholder="***_***_****"
                                    maxLength="14" // To ensure no extra characters
                                    className={errors.phone_number ? 'input-error' : ''}
                                />
                                {errors.phone_number && <p style={{color:"Red"}}>{errors.phone_number}</p>}
                            </Grid>
                            <Grid size={{sm:5.8, xs:12}} container flexDirection="column">
                                <span>Relationship</span>
                                <select name="relation_type" value={formData.relation_type} onChange={handleInputChange}>
                                    <option value="" disabled>Select</option>
                                    {relationships.map((relationship) => (
                                        <option key={relationship.id} value={relationship.value}>
                                        {relationship.value}
                                        </option>
                                    ))}
                                </select>
                                {errors.relation_type && <p style={{color:"Red"}}>{errors.relation_type}</p>}
                            </Grid>
                            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                            <Grid size={{sm:12, xs:12}} container justifyContent="space-between" className="mt-5">
                                <button className='registersendbtn1' type="button" onClick={() => navigate('/immigration-questions', { state: { fromNextPage: true } })}>Previous</button>
                                <button className='registersendbtn2' type='submit'>{submitLoader ? <img src={require('../../assets/whiteloader.gif')} alt=''/> : 'Save & Next'}</button>
                            </Grid>
                        </Grid>
                    </form>
                }
            </Grid>
        </Grid>
    </Grid>
  )
}

export default RegisterDetail3
