import React from 'react'
import Grid from '@mui/material/Grid2';
import "./login.css";
import { useNavigate } from 'react-router-dom';

function Loginselect() {
    const navigate = useNavigate()
  return (
    <Grid size={{xs:12}} container justifyContent="center" className="main_loginbg">
        <Grid size={{lg:11}} container justifyContent="space-evenly" alignItems="center">
            <Grid size={{md:6,sm:12,xs:12}} container alignItems="flex-start" className="left_buttlogin">
                <img src={require('../../assets/shortlogo.svg').default} alt=''/>
                <h2>Who you are ?</h2>
                <Grid size={{sm:12,xs:12}} container justifyContent="space-between">
                    <Grid size={{sm:3.8,xs:5.8}} container className="mb-3 can_selectbtn">
                        <button onClick={() =>{navigate("/termsandcondition")}}>Jobseeker</button>
                    </Grid>
                    <Grid size={{sm:3.8,xs:5.8}} container className="mb-3">
                        <button ><img src={require('../../assets/lockicon.svg').default} alt=''/>Freelancer</button>
                    </Grid>
                    <Grid size={{sm:3.8,xs:5.8}} container className="mb-3">
                        <button ><img src={require('../../assets/lockicon.svg').default} alt=''/>Recruiter</button>
                    </Grid>
                    
                    <Grid size={{sm:3.8,xs:5.8}} container className="mb-3">
                        <button><img src={require('../../assets/lockicon.svg').default} alt=''/>Employee</button>
                    </Grid>
                    <Grid size={{sm:3.8,xs:5.8}} container className="mb-3">
                        <button><img src={require('../../assets/lockicon.svg').default} alt=''/>ABC</button>
                    </Grid>
                    <Grid size={{sm:3.8,xs:5.8}} container className="mb-3">
                        
                    </Grid>
                </Grid>
            </Grid>
            <Grid size={{md:5,sm:12}} container flexDirection="column" alignItems="center" justifyContent="flex-end" className="leniarrightlogin">
                <h1>Your Career Awaits</h1>
                <h5>Join our vibrant community of <br/> job seekers and employers.</h5>
                <img src={require('../../assets/loginlady.png')} alt=''/>
            </Grid>
        </Grid>
    </Grid>
  )
}

export default Loginselect
