import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid2';
import "./login.css";
import { apiService } from '../../services/apiService';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { toast } from 'react-toastify';
function ResetPassword() {
  const [submitloader, setSubmitloader] = useState(false);
  const [resendloader, setResendloader] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { email } = location.state || '';
  const [errorMessage, setErrorMessage] = useState('');
  const [otp, setOtp] = useState('');
  const [password, setPassword] = useState('');
  const [confpassword, setconfPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordVisible2, setPasswordVisible2] = useState(false);
  // Validation states
  const [otpError, setOtpError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confPasswordError, setConfPasswordError] = useState('');
  const [otpResendTimer, setOtpResendTimer] = useState(0);
  const [timerActive, setTimerActive] = useState(false);


  const togglePasswordVisibility = (e) => {
    e.preventDefault();
    setPasswordVisible(!passwordVisible);
  };

  const togglePasswordVisibility2 = (e) => {
    e.preventDefault();
    setPasswordVisible2(!passwordVisible2);
  };

  useEffect(() => {
    let interval;
    if (timerActive && otpResendTimer > 0) {
      interval = setInterval(() => {
        setOtpResendTimer((prevTime) => prevTime - 1);
      }, 1000);
    } else if (otpResendTimer === 0) {
      setTimerActive(false);
    }
    return () => clearInterval(interval);
  }, [timerActive, otpResendTimer]);

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  // Function to handle form submission
  const handleForget = async (e) => {
    e.preventDefault(); // Prevent page reload on form submission
    let valid = true;

    // Validate OTP (must be exactly 6 digits)
    if (otp.length !== 6 || isNaN(otp)) {
      setOtpError('OTP must be a 6-digit number.');
      valid = false;
    } else {
      setOtpError('');
    }
    const passwordFormat = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_#])[A-Za-z\d@$!%*?&_#]{8,}$/;
    if (!passwordFormat.test(password)) {
      setPasswordError('Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and any one special character.');
      valid = false;
    }
    else {
      setPasswordError('');
    }

    // Validate Confirm Password (must match password)
    if (confpassword !== password) {
      setConfPasswordError('Passwords do not match.');
      valid = false;
    } else {
      setConfPasswordError('');
    }

    if (valid) {
      setSubmitloader(true);
      try {
        // Call API to reset password
        const Response = await apiService.postRequest('auth/reset-password', {
          email: email,
          otp: otp,
          password: password,
          password_confirmation: confpassword,
        });
        if (Response && Response.success === true) {
          navigate('/login');
          toast("Your password has been Reset!",{
            autoClose: 10000,
            });
        } else {
          setErrorMessage(Response.message || 'Reset Password Failed');
        }
      } catch (error) {
        setErrorMessage('Reset Password Failed');
      }finally {
        setSubmitloader(false);
      }
    }
  };
  const resendForget = async (e) => {
    e.preventDefault(); // Prevent page reload on form submission
    setResendloader(true);
    try {
      const Response = await apiService.postRequest('auth/forgot-password', {
        email: email,
      });
      if (Response && Response.success === true) {
        toast("OTP sent to Your Email!");
      } else {
        setErrorMessage(Response.message || "We can't find a user with that e-mail address.");
        setTimerActive(true);
        setOtpResendTimer(Response.remaining_seconds);
      }
    } catch (error) {
      setErrorMessage("We can't find a user with that e-mail address.");
    }finally {
      setResendloader(false);
    }
  };
  return (
    <Grid size={{xs:12}} container justifyContent="center" className="main_loginbg">
        <Grid size={{lg:11}} container justifyContent="space-evenly" alignItems="center">
            <Grid size={{md:5,xs:11}} container flexDirection="column" alignItems="flex-start" justifyContent="flex-end" className="left_inputlogin">
                <img src={require('../../assets/shortlogo.svg').default} alt=''/>
                <h2>Reset Password</h2>
                <form className='d-flex w-100 flex-column' onSubmit={handleForget}>
                <span>OTP</span>
                <input id="otp" value={otp} onChange={(e) => setOtp(e.target.value)} type="text" placeholder="------" maxLength={6} className='otp_input'/>
                <div className='d-flex justify-content-end gap-5'>
                  {timerActive ?
                    <small className='resend_otpsmallTimer'>{timerActive ? `Resend OTP in ${formatTime(otpResendTimer)}` : ''}</small>:
                    <small onClick={resendForget} className='resend_otpsmall'>{resendloader ? <img src={require('../../assets/pinkloader.gif')} alt=''/> : ''}Resend OTP</small>
                  }
                </div>
                {otpError && <p style={{ color: 'red' }}>{otpError}</p>}
                    <span>Reset Password</span>
                    <div className='d-flex input_witheye'>
                        <input
                            id="password" value={password} onChange={(e) => setPassword(e.target.value)}
                            type={passwordVisible ? 'text' : 'password'}
                            placeholder="Abcd123#"
                            style={{ width: '100%', paddingRight: '40px' }}
                        />
                        <button type="button" onClick={togglePasswordVisibility}>
                            {passwordVisible ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
                        </button>
                    </div>
                    {passwordError && <p style={{ color: 'red' }}>{passwordError}</p>}
                    <span>Confirm Password</span>
                    <div className='d-flex input_witheye'>
                        <input
                            id="confpassword" value={confpassword} onChange={(e) => setconfPassword(e.target.value)}
                            type={passwordVisible2 ? 'text' : 'password'}
                            placeholder="Abcd123#"
                            style={{ width: '100%', paddingRight: '40px' }}
                        />
                        <button type="button" onClick={togglePasswordVisibility2}>
                            {passwordVisible2 ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
                        </button>
                    </div>
                    {confPasswordError && <p style={{ color: 'red' }}>{confPasswordError}</p>}
                    {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                    <button type="submit" className='mt-4'>{submitloader ? <img src={require('../../assets/whiteloader.gif')} alt=''/> : 'Submit'}</button>
                </form>
            </Grid>
            <Grid size={{md:5,sm:12}} container flexDirection="column" alignItems="center" justifyContent="flex-end" className="leniarrightlogin">
                <h1>Your Career Awaits</h1>
                <h5>Join our vibrant community of <br/> job seekers and employers.</h5>
                <img src={require('../../assets/loginlady.png')} alt=''/>
            </Grid>
        </Grid>
    </Grid>
  )
}

export default ResetPassword
