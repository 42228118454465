import React from 'react'
import Grid from '@mui/material/Grid2';
import "./catalogs.css";
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
function Feedback() {
    const navigate = useNavigate();
  return (
    <div className='container-fluid px-0'>
        <div className='content_container'>
            <Grid size={{sm:12}} container className="main_jobseeker">
                <Grid size={{sm:12}} container alignItems="center">
                    <Grid size={{md:8,xs:12}} container flexDirection="column" className="jobseekerhead">
                        <h3>Anonymous feedback</h3>
                        <span><b onClick={()=>navigate("/dashboard")}>Dashboard</b> <FontAwesomeIcon className='angleright' icon={faAngleRight} /> <b>Catalog</b><FontAwesomeIcon className='angleright' icon={faAngleRight} /> Anonymous feedback</span>
                    </Grid>
                </Grid>
                <Grid size={{md:12,xs:12}} container justifyContent="flex-end" alignItems="center" className="my-4 request_user_edit">
                    <Grid size={{xs:12}} container justifyContent="space-between" className="mt-4">
                        <Grid size={{md:4,sm:3,xs:12}} container className="mt-3">
                            <img src={require('../../../assets/Requestedits/feedback.png')} alt=''/>
                        </Grid>
                        <Grid size={{md:7,sm:8,xs:12}} container flexDirection="column" justifyContent="flex-start" className="mt-3 requestedit_quat px-3">
                            <h3>Anonymous feedback</h3>
                            <span>Use this form to submit BenchTrack Certifications</span>
                            <span>If you are trying to start or switch your career to ServiceNow, its important to get certified to boost your chances of landing in an interview. Beware of ServiceNow Certification Scams, Do not purchase 3rd party vouchers, Do not purchase/use Dumps.</span>
                        </Grid>
                        <Grid size={{xs:12}} container>
                            <form className='w-100' 
                            // onSubmit={handleSubmit}
                            >
                                <Grid size={{lg:12,xs:12}} container justifyContent="space-between" className="regiter_inputs mt-4">
                                    <Grid size={{sm:12, xs:12}} container flexDirection="column">
                                        <span>What would you like to let us know ?*<span style={{color:"Red"}}> *</span></span>
                                        <textarea placeholder='Describe' rows="10" name="renew_authorization_work"
                                            // value={formData.renew_authorization_work}
                                            // onChange={handleInputChange}
                                            />
                                    </Grid>
                                    <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                        <span>Who are you ? (if you want to follow up after we've addressed the issue - NOT REQUIRED )<span style={{color:"red"}}> *</span></span>
                                        <input
                                            type="text"
                                            name="short"
                                        />
                                    </Grid>
                                    <Grid size={{sm:12, xs:12}} container justifyContent="space-between" className="mt-2">
                                        <button className='registersendbtn2'>Submit</button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    </div>
  )
}

export default Feedback
