import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid2';
import "./catalogs.css";
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
function ReportTechIssue() {
    const navigate = useNavigate();
    const [maxDate, setMaxDate] = useState('');
    useEffect(() => {
        const today = dayjs();
        setMaxDate(today.format('YYYY-MM-DD'));
      }, []);
  return (
    <div className='container-fluid px-0'>
        <div className='content_container'>
            <Grid size={{sm:12}} container className="main_jobseeker">
                <Grid size={{sm:12}} container alignItems="center">
                    <Grid size={{md:8,xs:12}} container flexDirection="column" className="jobseekerhead">
                        <h3>Report technical issue</h3>
                        <span><b onClick={()=>navigate("/dashboard")}>Dashboard</b> <FontAwesomeIcon className='angleright' icon={faAngleRight} /> <b>Catalog</b><FontAwesomeIcon className='angleright' icon={faAngleRight} /> Report technical issue</span>
                    </Grid>
                </Grid>
                <Grid size={{md:12,xs:12}} container justifyContent="flex-end" alignItems="center" className="my-4 request_user_edit">
                    <Grid size={{xs:12}} container justifyContent="space-between" className="mt-4">
                        <Grid size={{md:4,sm:3,xs:12}} container className="mt-3">
                            <img src={require('../../../assets/Requestedits/report_tec_issue.png')} alt=''/>
                        </Grid>
                        <Grid size={{md:7,sm:8,xs:12}} container flexDirection="column" justifyContent="flex-start" className="mt-3 requestedit_quat px-3">
                            <h3>Report technical issue</h3>
                            <span>Use this form to submit BenchTrack Certifications</span>
                            <span>If you are trying to start or switch your career to ServiceNow, its important to get certified to boost your chances of landing in an interview. Beware of ServiceNow Certification Scams, Do not purchase 3rd party vouchers, Do not purchase/use Dumps.</span>
                        </Grid>
                        <Grid size={{xs:12}} container>
                            <form className='w-100' 
                            // onSubmit={handleSubmit}
                            >
                                <Grid size={{lg:12,xs:12}} container justifyContent="space-between" className="regiter_inputs">
                                    <Grid size={{md:5.5,sm:5.8, xs:12}} container className="my-3" flexDirection="column">
                                        <span>Are you an active Jobseeker with Tekpossible ?<span style={{color:"red"}}> *</span></span>
                                        <div className='d-flex align-items-center main_radioregister'>
                                            <input
                                                type="radio" 
                                                name="laws_and_regulations" 
                                                // checked={formData.laws_and_regulations} 
                                                // onChange={handleInputChange} 
                                            />Yes
                                            <input
                                                type="radio" 
                                                name="laws_and_regulations" 
                                                // checked={formData.laws_and_regulations} 
                                                // onChange={handleInputChange} 
                                            />No
                                        </div>
                                    </Grid>
                                    <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                        <span>When did you first notice the issue ?<span style={{color:"red"}}> *</span></span>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                className="date_pickermux"
                                                // value={value2}
                                                // onChange={handleDateChange}
                                                maxDate={dayjs(maxDate)}
                                                renderInput={(params) => <input {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                        <span>Your Name<span style={{color:"red"}}> *</span></span>
                                        <input
                                            type="text"
                                            name="first_name"
                                            onInput={(e) => e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, '')}
                                        />
                                    </Grid>
                                    <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                        <span>Email<span style={{color:"red"}}> *</span></span>
                                        <input
                                            type="email"
                                            name="email"
                                        />
                                    </Grid>
                                    <Grid size={{ xs:12}} container>
                                        <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                            <span>Short Description<span style={{color:"red"}}> *</span></span>
                                            <input
                                                type="text"
                                                name="short"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid size={{sm:12, xs:12}} container flexDirection="column">
                                        <span>Description<span style={{color:"Red"}}> *</span></span>
                                        <textarea placeholder='Describe' rows="10" name="renew_authorization_work"
                                            // value={formData.renew_authorization_work}
                                            // onChange={handleInputChange}
                                            />
                                    </Grid>
                                    <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                        <span>Please attach screenshots describing the issue</span>
                                        <div className="fileUploadInput">
                                            <input type="file" id="proof_of_work_authorization" name="proof_of_work_authorization" 
                                            // onChange={handleFileChange}
                                            />
                                            <label htmlFor="proof_of_work_authorization">Upload</label>
                                        </div>
                                    </Grid>
                                    <Grid size={{sm:12, xs:12}} container justifyContent="space-between" className="mt-2">
                                        <button className='registersendbtn2'>Submit</button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    </div>
  )
}

export default ReportTechIssue
