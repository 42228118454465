import React, { useState } from 'react'
import Grid from '@mui/material/Grid2';
import "./validations.css"
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import ValidateCats from '../../../../components/common/ValidateCats';
import Select from 'react-select';
// import { toast } from 'react-toastify';
// import { apiService } from '../../../../services/apiService';
function Assignments() {
    // const { requestId } = useParams();
    // const roles = localStorage.getItem('role');
    const navigate = useNavigate();
    const [isVisible, setIsVisible] = useState(true);
    const toggleVisibility = () => {
        setIsVisible(!isVisible);
      };
      const closeElement = () => {
        setIsVisible(true);
      };
  
  return (
    <div className='container-fluid px-0'>
        <div className={isVisible ? 'hide_mobitr' : 'show_mobitr'}>
            <ValidateCats onClose={closeElement}/>
        </div>
        <div className='content_container_validate' style={{ flexGrow: 1 }}>
            <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead mb-3">
                <span><b onClick={()=>navigate("/dashboard")}>Dashboard</b> <FontAwesomeIcon className='angleright' icon={faAngleRight} /> Case10000</span>
            </Grid>
            <Grid size={{sm:12}} container className="main_jobseeker">
                <Grid size={{sm:12}} container alignItems="center">
                    <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead border_botomer">
                        <h3>I94 Validation</h3>
                        <b>Jobseeker Name</b>
                        <button onClick={toggleVisibility} className='d-md-none main_category_btn'>
                            List
                        </button>
                    </Grid>
                    <Grid size={{xs:12}} container justifyContent="flex-start" className="statusselectbtn mt-4">
                        <div className='knowledgetab_btnredirect'>
                            <button>Overview</button>
                            <button className="active_tabbtn">Assignments</button>
                            <button>Discussions</button>
                            <button>Knowledge</button>
                            <button>History</button>
                        </div>
                    </Grid>
                    <form className='w-100' 
                        // onSubmit={handleSubmit}
                        >
                            <Grid size={{lg:12,xs:12}} container justifyContent="space-between" className="darfts_inputs for_textarespec mt-4">
                                <Grid size={{sm:5.6, xs:12}} container flexDirection="column">
                                    <span>Assignment Group</span>
                                    <Select
                                        isMulti
                                        className="basic-single select_withsearch"
                                        classNamePrefix="select"
                                        // value={selectedTask}
                                        defaultValue={null}
                                        name="task_type"
                                        // options={taskData.map((task) => ({
                                        //     label: task.label,
                                        //     value: task.value,
                                        // }))}
                                        // onChange={handleTaskChange}
                                    />
                                    {/* {errors.task_type && <p style={{color:"Red"}}>{errors.task_type}</p>} */}
                                </Grid>
                                <Grid size={{sm:5.6, xs:12}} container flexDirection="column">
                                    <span>Assigned To</span>
                                    <Select
                                        className="basic-single select_withsearch"
                                        classNamePrefix="select"
                                        // value={selectedCatId}
                                        defaultValue={null}
                                        name="category_id"
                                        // options={categoryId.map((cates) => ({
                                        //     label: cates.name,
                                        //     value: cates.id,
                                        // }))}
                                        // onChange={handleCatidChange}
                                    />
                                    {/* {errors.category_id && <p style={{color:"Red"}}>{errors.category_id}</p>} */}
                                </Grid>
                                <Grid size={{sm:12, xs:12}} container flexDirection="column">
                                    <span>Reason for re-assignment</span>
                                    <textarea placeholder='Write your reason'/>
                                    {/* {errors.category_id && <p style={{color:"Red"}}>{errors.category_id}</p>} */}
                                </Grid>
                                <Grid size={{sm:12, xs:12}} container justifyContent="space-between" className="mt-2">
                                    <button type='submit' className='registersendbtn2'>Save</button>
                                    {/* <button type='submit' className='registersendbtn2'>{submitloader ? <img src={require('../../../assets/whiteloader.gif')} alt=''/> : 'Send Request'}</button> */}
                                </Grid>
                            </Grid>
                        </form>
                </Grid>
            </Grid>
        </div>
    </div>
  )
}

export default Assignments
