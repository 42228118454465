import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid2';
import "./login.css";
import { apiService } from '../../services/apiService';
import { useNavigate } from 'react-router-dom';
function ForgetPassword() {

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])
  const [submitloader, setSubmitloader] = useState(false);
    const navigate = useNavigate()
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [otpResendTimer, setOtpResendTimer] = useState(0);
  const [timerActive, setTimerActive] = useState(false);

  useEffect(() => {
    let interval;
    if (timerActive && otpResendTimer > 0) {
      interval = setInterval(() => {
        setOtpResendTimer((prevTime) => prevTime - 1);
      }, 1000);
    } else if (otpResendTimer === 0) {
      setTimerActive(false);
    }
    return () => clearInterval(interval);
  }, [timerActive, otpResendTimer]);

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  // Function to handle form submission
  const handleForget = async (e) => {
    e.preventDefault(); // Prevent page reload on form submission
    setSubmitloader(true);
    try {
      const Response = await apiService.postRequest('auth/forgot-password', {
        email: email,
      });
      if (Response && Response.success === true) {
        
        navigate('/resetpassword',{ state: { email } });
      } else {
        setErrorMessage(Response.message || "We can't find a user with that e-mail address.");
        setTimerActive(true);
        setOtpResendTimer(Response.remaining_seconds);
      }
    } catch (error) {
      setErrorMessage("We can't find a user with that e-mail address.");
    }finally {
      setSubmitloader(false); // Stop loader
    }
  };
  return (
    <Grid size={{xs:12}} container justifyContent="center" className="main_loginbg">
        <Grid size={{lg:11}} container justifyContent="space-evenly" alignItems="center">
            <Grid size={{md:5,xs:11}} container flexDirection="column" alignItems="flex-start" justifyContent="flex-end" className="left_inputlogin">
                <img src={require('../../assets/shortlogo.svg').default} alt=''/>
                <h2>Forgot Password</h2>
                <form className='d-flex w-100 flex-column' onSubmit={handleForget}>
                    <span>User Email</span>
                    <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} required/>
                    {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                    {timerActive ?
                      <small className='resend_otpsmallTimer'>{timerActive ? `Send OTP in ${formatTime(otpResendTimer)}` : ''}</small>:
                      <button type="submit" className='mt-4'>{submitloader ? <img src={require('../../assets/whiteloader.gif')} alt=''/> : 'Submit'}</button>
                    }
                </form>
            </Grid>
            <Grid size={{md:5,sm:12}} container flexDirection="column" alignItems="center" justifyContent="flex-end" className="leniarrightlogin">
                <h1>Your Career Awaits</h1>
                <h5>Join our vibrant community of <br/> job seekers and employers.</h5>
                <img src={require('../../assets/loginlady.png')} alt=''/>
            </Grid>
        </Grid>
    </Grid>
  )
}

export default ForgetPassword
