import React, { useState } from 'react'
import Grid from '@mui/material/Grid2';
import "./validations.css"
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import ValidateCats from '../../../../components/common/ValidateCats';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { toast } from 'react-toastify';
// import { apiService } from '../../../../services/apiService';
function I94Validation() {
    // const { requestId } = useParams();
    // const roles = localStorage.getItem('role');
    const navigate = useNavigate();
    const [isVisible, setIsVisible] = useState(true);
    const toggleVisibility = () => {
        setIsVisible(!isVisible);
      };
      const closeElement = () => {
        setIsVisible(true);
      };
  
  return (
    <div className='container-fluid px-0'>
        <div className={isVisible ? 'hide_mobitr' : 'show_mobitr'}>
            <ValidateCats onClose={closeElement}/>
        </div>
        <div className='content_container_validate' style={{ flexGrow: 1 }}>
            <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead mb-3">
                <span><b onClick={()=>navigate("/dashboard")}>Dashboard</b> <FontAwesomeIcon className='angleright' icon={faAngleRight} /> Case10000</span>
            </Grid>
            <Grid size={{sm:12}} container className="main_jobseeker">
                <Grid size={{sm:12}} container alignItems="center">
                    <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead border_botomer">
                        <h3>I94 Validation</h3>
                        <b>Jobseeker Name</b>
                        <button onClick={toggleVisibility} className='d-md-none main_category_btn'>
                            List
                        </button>
                    </Grid>
                    <Grid size={{xs:12}} container justifyContent="flex-start" className="statusselectbtn mt-4">
                        <div className='knowledgetab_btnredirect'>
                            <button className="active_tabbtn">Overview</button>
                            <button>Assignments</button>
                            <button>Discussions</button>
                            <button>Knowledge</button>
                            <button>History</button>
                        </div>
                    </Grid>
                    <Grid size={{xs:12}} container justifyContent="flex-start" className="mt-4">
                        <div className='d-flex align-items-start note_bgyesno w-100'>
                            <b>Note:</b>
                            <span className='d-flex flex-column'>
                                <small>“If you says yes, you are granting approval for Jobseeker to proceed further.”</small>
                                <small>“If you says No, you are declining permission for Jobseeker to continue.”</small>
                            </span>
                        </div>
                    </Grid>
                    <form className='w-100' 
                        // onSubmit={handleSubmit}
                        >
                        {/* <Grid size={{sm:12, xs:12}} container justifyContent="flex-end" className="mt-2">
                            <button type='submit' className='registersendbtn2 mb-0'>{submitloader ? <img src={require('../../../assets/whiteloader.gif')} alt=''/> : 'Send Request'}</button>
                        </Grid> */}
                        
                        <Grid size={{lg:12,xs:12}} container justifyContent="space-between" className="regiter_inputs i94validateinput">
                            <Grid size={{sm:12}} container className="my-3" flexDirection="column">
                                <span>Did Jobseeker Submit a valid I94 document in the application ?<small>*</small></span>
                                <div className='d-flex align-items-center main_radioregister'>
                                    <input
                                        type="radio" 
                                        name="laws_and_regulations"
                                        // checked={formData.laws_and_regulations} 
                                        // onChange={handleInputChange} 
                                    />Yes
                                    <input
                                        type="radio" 
                                        name="laws_and_regulations"
                                        // checked={formData.laws_and_regulations} 
                                        // onChange={handleInputChange} 
                                    />No
                                </div>
                                {/* {errors.laws_and_regulations && <p style={{color:"Red"}}>{errors.laws_and_regulations}</p>} */}
                            </Grid>
                            <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                <span>Passport Number of Jobseeker<small>*</small></span>
                                <input
                                    type="text"
                                    name="first_name"
                                    // value={formData.first_name}
                                    // onChange={handleInputChange} 
                                    // onInput={(e) => e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, '')}
                                />
                                {/* {errors.first_name && <p style={{color:"Red"}}>{errors.first_name[0]}</p>} */}
                            </Grid>
                            <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                <span>Jobseeker type<small>*</small></span>
                                <select name="gender" 
                                // value={formData.gender}
                                // onChange={handleInputChange} 
                                >
                                    <option value="">Select Type</option>
                                    <option value="1">Existing</option>
                                    <option value="">Type 2</option>
                                    <option value="">Type 3</option>
                                </select>
                                {/* {errors.gender && <p style={{color:"Red"}}>{errors.gender[0]}</p>} */}
                            </Grid>
                            <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                <span>Passport Number of Jobseeker<small>*</small></span>
                                <input
                                    type="text"
                                    name="last_name"
                                    // value={formData.last_name}
                                    // onChange={handleInputChange} 
                                    // onInput={(e) => e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, '')}
                                />
                                {/* {errors.last_name && <p style={{color:"Red"}}>{errors.last_name[0]}</p>} */}
                            </Grid>
                            <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                <span>Last name of the Jobseeker as per I-94<small>*</small></span>
                                <input
                                    type="text"
                                    name="last_name"
                                    // value={formData.last_name}
                                    // onChange={handleInputChange} 
                                    // onInput={(e) => e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, '')}
                                />
                                {/* {errors.last_name && <p style={{color:"Red"}}>{errors.last_name[0]}</p>} */}
                            </Grid>
                            <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                <span>Date of Birth of the Jobseeker as per I-94<small>*</small></span>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            className="date_pickermux"
                                            // value={value}
                                            // onChange={handleDateChange}
                                            // maxDate={dayjs().subtract(18, 'year')}
                                            renderInput={(params) => <input {...params} />}
                                        />
                                    </LocalizationProvider>
                                    {/* {errors.dob && <p style={{color:"Red"}}>{errors.dob[0]}</p>} */}
                            </Grid>
                            
                            <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                <span>Upload the latest I-94 of the Jobseeker<small>*</small></span>
                                <div className="fileUploadInput">
                                <input
                                    type="file"
                                    name="i94_copy"
                                    id="i94_copy"
                                    // onChange={handleFileChange}
                                    style={{ display: 'none' }} // Hide the actual file input
                                />
                                <label htmlFor="i94_copy" style={{ cursor: 'pointer' }}>Upload</label>
                                <input
                                    type="text"
                                    // value={fileNames.i94_copy || ''}
                                    readOnly
                                    placeholder="No file chosen"
                                />
                            </div>
                                {/* {errors.i94_copy && <p style={{color:"Red"}}>{errors.i94_copy[0]}</p>} */}
                            </Grid>                            
                            <Grid size={{sm:12, xs:12}} container justifyContent="space-between" className="mt-2">
                                <button type='submit' className='registersendbtn2'>Save</button>
                                {/* <button type='submit' className='registersendbtn2'>{submitloader ? <img src={require('../../../assets/whiteloader.gif')} alt=''/> : 'Send Request'}</button> */}
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            </Grid>
        </div>
    </div>
  )
}

export default I94Validation
