import React, { useState } from 'react'
import Grid from '@mui/material/Grid2';
import "./validations.css"
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import ValidateCats from '../../../../components/common/ValidateCats';
// import { toast } from 'react-toastify';
// import { apiService } from '../../../../services/apiService';
function TaskDisccuss() {
    // const { requestId } = useParams();
    // const roles = localStorage.getItem('role');
    const navigate = useNavigate();
    const [isVisible, setIsVisible] = useState(true);
    const toggleVisibility = () => {
        setIsVisible(!isVisible);
      };
      const closeElement = () => {
        setIsVisible(true);
      };
  
  return (
    <div className='container-fluid px-0'>
        <div className={isVisible ? 'hide_mobitr' : 'show_mobitr'}>
            <ValidateCats onClose={closeElement}/>
        </div>
        <div className='content_container_validate' style={{ flexGrow: 1 }}>
            <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead mb-3">
                <span><b onClick={()=>navigate("/dashboard")}>Dashboard</b> <FontAwesomeIcon className='angleright' icon={faAngleRight} /> Case10000</span>
            </Grid>
            <Grid size={{sm:12}} container className="main_jobseeker">
                <Grid size={{sm:12}} container alignItems="center">
                    <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead border_botomer">
                        <h3>I94 Validation</h3>
                        <b>Jobseeker Name</b>
                        <button onClick={toggleVisibility} className='d-md-none main_category_btn'>
                            List
                        </button>
                    </Grid>
                    <Grid size={{xs:12}} container justifyContent="flex-start" className="statusselectbtn mt-4">
                        <div className='knowledgetab_btnredirect'>
                            <button>Overview</button>
                            <button>Assignments</button>
                            <button className="active_tabbtn">Discussions</button>
                            <button>Knowledge</button>
                            <button>History</button>
                        </div>
                    </Grid>
                    <Grid size={{xs:12}} container>
                        <form className='w-100 mt-3' 
                        // onSubmit={handleSubmit}
                        >
                            <Grid size={{sm:12}} container className="mt-4">
                                <label className='custom_comonlabel'>Work Notes</label>
                                <textarea 
                                style={{background:"#fff"}}
                                className='comment_textarea' 
                                placeholder="write notes...." 
                                // value={comment} 
                                // onChange={(e) => setComment(e.target.value)} 
                                />
                                {/* <div className='w-100'>
                                    {errors.comment && <p style={{color:"red"}}>{errors.comment}</p>}
                                </div> */}
                                <button type="submit" className='submit_btn'>Save</button>
                            </Grid>
                        </form>
                        <Grid size={{sm:12}} container justifyContent="center" className="mt-4 previous_comments discurssionoverflow">
                                <Grid size={{ sm: 12 }} container alignItems="center" justifyContent="space-between" className="mb-4 reply_comment_btn dicussion_heightset">
                                <small className='pink_small'>@member 1</small>
                                <small className='datetime_small'>09-25-2024 12:30:18</small>
                                <span>Develop a comprehensive orientation program to introduce new hires to the company culture and policies.</span>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    </div>
  )
}

export default TaskDisccuss
