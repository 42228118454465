import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid2';
import "./admin.css";
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { apiService } from '../../services/apiService';
function JobseekerProfile() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [profiles, setProfile] = useState([]);
    const [profiles2, setProfile2] = useState([]);
    const [profiles3, setProfile3] = useState([]);
    const [profiles4, setProfile4] = useState([]);

    useEffect(() => {
        const fetchUserData = async () => {
            setLoading(true);
            try {
                const response = await apiService.getRequest(`/admin/jobseeker/${id}`);
                if (response.success === true){
                    setProfile(response.data);
                    if(response.data.immigration_data.length !== 0){
                        setProfile2(response.data.immigration_data);
                    }
                    if(response.data.emergency_contact.length !== 0){
                        setProfile3(response.data.emergency_contact);
                    }
                    if(response.data.referral_info.length !== 0){
                        setProfile4(response.data.referral_info);
                    }
                }else{
                    toast.error("Retry")
                }
            } catch (error) {
                console.error("Error fetching user data", error);
            }finally {
                setLoading(false);
            }
        };
        fetchUserData();
    }, [id]);
  return (
    <div className='container-fluid px-0'>
        <div className='content_container'>
            <Grid size={{lg:12}} container className="main_jobseeker">
                <Grid size={{lg:12}} container alignItems="center" justifyContent="center">
                    <Grid size={{md:12,xs:12}} container alignItems="flex-start" justifyContent="space-between" flexDirection="row" className="jobseekerhead">
                        <h3>Jobseekers Profile</h3>
                        <button className='back_buttonminus' onClick={() => navigate(-1)}>Back</button>
                        {/* <span><b>Dashboard</b> <FontAwesomeIcon className='angleright' icon={faAngleRight} /> Jobseekers</span> */}
                    </Grid>
                    {loading ? 
                    <div className='d-flex justify-content-center w-100'>
                        <img style={{ width: "100px" }} src={require('../../assets/pinkloader.gif')} alt='Loading...' />
                    </div> :
                        <Grid size={{md:11,xs:12}} container justifyContent="space-between" className="jobseekers_profilealls my-4">
                            <Grid size={{xs:12}} container>
                                <h4>Personal Information</h4>
                            </Grid>
                            <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                <span>First Name</span>
                                <p>{profiles.first_name}</p>
                            </Grid>
                            <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                <span>Last Name</span>
                                <p>{profiles.last_name}</p>
                            </Grid>
                            <Grid size={{sm:12,xs:12}} container flexDirection="column" className="seekprof_borders">
                                <span>Email Id</span>
                                <p>{profiles.email}</p>
                            </Grid>
                            <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                <span>Date of Birth</span>
                                <p>{profiles.dob}</p>
                            </Grid>
                            <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                <span>Gender</span>
                                <p className='capssets'>{profiles.gender}</p>
                            </Grid>
                            <Grid size={{sm:12,xs:12}} container flexDirection="column" className="seekprof_borders">
                                <span>Address</span>
                                <p className='capssets'>{profiles.address}</p>
                            </Grid>
                            <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                <span>City</span>
                                <p className='capssets'>{profiles.city}</p>
                            </Grid>
                            <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                <span>State</span>
                                <p className='capssets'>{profiles.state}</p>
                            </Grid>
                            <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                <span>Zip Code</span>
                                <p>{profiles.zipcode}</p>
                            </Grid>
                            <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                <span>Phone Number</span>
                                <p>{profiles.phone_number}</p>
                            </Grid>
                            <Grid size={{sm:12,xs:12}} container flexDirection="column" className="seekprof_borders">
                                <span>Why do you want to enroll with us?</span>
                                <p className='capssets'>{profiles.why_enroll}</p>
                            </Grid>
                            {profiles2 && profiles2.length === 0 ? 
                            "":<>
                            <Grid size={{xs:12}} container>
                                <h4>Immigration Information</h4>
                            </Grid>
                            <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                <span>Citizenship Status</span>
                                <p className='capssets'>{profiles2.citizenship_status}</p>
                            </Grid>
                            <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                <span>Are you waiting for your EAD Card to Arrive?</span>
                                <p className='capssets'>{profiles2.awaiting_ead_card || "No data"}</p>
                            </Grid>
                            <Grid size={{sm:12,xs:12}} container flexDirection="column" className="seekprof_borders">
                                <span>When did you apply for your EAD Card?</span>
                                <p className='capssets'>{profiles2.ead_card_applied_date || "No data"}</p>
                            </Grid>
                            <Grid size={{sm:12,xs:12}} container flexDirection="column" className="seekprof_borders">
                                <span>Explain your citizenship status</span>
                                <p className='capssets'>{profiles2.citizenship_explanation}</p>
                            </Grid>
                            <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                <span>Are you legally authorized to work in United States ?</span>
                                <p className='capssets'>{profiles2.legally_authorized_to_work}</p>
                            </Grid>
                            <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                <span>When will your work authorization expire ?</span>
                                <p className='capssets'>{profiles2.work_authorization_expiry || "No data"}</p>
                            </Grid>
                            <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                <span>Which year was your SSN Issued ?</span>
                                <p className='capssets'>{profiles2.ssn_issue_year || "No data"}</p>
                            </Grid>
                            <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                <span>Upload Most Recent I94 Copy</span>
                                <a href={`${profiles2.i94_copy}`} target="_blank" rel="noopener noreferrer">View</a>
                            </Grid>
                            <Grid size={{sm:12,xs:12}} container flexDirection="column" className="seekprof_borders">
                                <span>I hereby authorize agents of TekPossible Consulting to access and verify the authenticity of my uploaded I-94 document through the official I-94 website (https://i94.cbp.dhs.gov/I94). I understand that this verification will be conducted in accordance with applicable laws and regulations.</span>
                                <p>Yes</p>
                            </Grid>
                            <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                <span>Upload Proof of Work Authorization</span>
                                <a href={`${profiles2.proof_of_work_authorization}`} target="_blank" rel="noopener noreferrer">View</a>
                            </Grid>
                            <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                <span>Upload Profile Picture</span>
                                <a href={`${profiles2.profile}`} target="_blank" rel="noopener noreferrer">View</a>
                            </Grid>
                            </>}
                            {profiles3 && profiles3.length === 0 ?
                            "":<>
                            <Grid size={{sm:12,xs:12}} container>
                                <h4>Emergency Contact</h4>
                            </Grid>
                            <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                <span>First Name</span>
                                <p className='capssets'>{profiles3.first_name}</p>
                            </Grid>
                            <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                <span>Last Name</span>
                                <p className='capssets'>{profiles3.last_name}</p>
                            </Grid>
                            <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                <span>Phone Number</span>
                                <p className='capssets'>{profiles3.phone_number}</p>
                            </Grid>
                            <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                <span>Relationship</span>
                                <p className='capssets'>{profiles3.relation_type}</p>
                            </Grid>
                            </>}
                            {profiles4 && profiles4.length === 0 ? 
                            "":<>
                            <Grid size={{sm:12,xs:12}} container>
                                <h4>Referral Information</h4>
                            </Grid>
                            <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                <span>Referrer Phone Number</span>
                                <p className='capssets'>{profiles4.referral_phone_number}</p>
                            </Grid>
                            <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                <span>Referrer Name</span>
                                <p className='capssets'>{profiles4.referrer_name}</p>
                            </Grid>
                            <Grid size={{sm:12,xs:12}} container flexDirection="column" className="seekprof_borders">
                                <span>How do you know the referrer ?</span>
                                <p className='capssets'>{profiles4.referrer_relationship}</p>
                            </Grid>
                            </>}
                        </Grid>
                    }
                </Grid>
            </Grid>
        </div>
    </div>
  )
}

export default JobseekerProfile
