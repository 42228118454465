import React, { useState } from 'react'
import Grid from '@mui/material/Grid2';
import "./validations.css"
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import ValidateCats from '../../../../components/common/ValidateCats';
// import { toast } from 'react-toastify';
// import { apiService } from '../../../../services/apiService';
function TaskHistory() {
    // const { requestId } = useParams();
    // const roles = localStorage.getItem('role');
    const navigate = useNavigate();
    const [isVisible, setIsVisible] = useState(true);
    const toggleVisibility = () => {
        setIsVisible(!isVisible);
      };
      const closeElement = () => {
        setIsVisible(true);
      };
  
  return (
    <div className='container-fluid px-0'>
        <div className={isVisible ? 'hide_mobitr' : 'show_mobitr'}>
            <ValidateCats onClose={closeElement}/>
        </div>
        <div className='content_container_validate' style={{ flexGrow: 1 }}>
            <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead mb-3">
                <span><b onClick={()=>navigate("/dashboard")}>Dashboard</b> <FontAwesomeIcon className='angleright' icon={faAngleRight} /> Case10000</span>
            </Grid>
            <Grid size={{sm:12}} container className="main_jobseeker">
                <Grid size={{sm:12}} container alignItems="center">
                    <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead border_botomer">
                        <h3>I94 Validation</h3>
                        <b>Jobseeker Name</b>
                        <button onClick={toggleVisibility} className='d-md-none main_category_btn'>
                            List
                        </button>
                    </Grid>
                    <Grid size={{xs:12}} container justifyContent="flex-start" className="statusselectbtn mt-4">
                        <div className='knowledgetab_btnredirect'>
                            <button>Overview</button>
                            <button>Assignments</button>
                            <button>Discussions</button>
                            <button>Knowledge</button>
                            <button className="active_tabbtn">History</button>
                        </div>
                    </Grid>
                    <Grid size={{xs:12}} container justifyContent="center">

                    </Grid>
                    
                </Grid>
            </Grid>
        </div>
    </div>
  )
}

export default TaskHistory
