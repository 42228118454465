import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid2';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
// import { useNavigate } from 'react-router-dom';
import { MenuItem, TextField } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faFilter, faFilterCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import { apiService } from '../../services/apiService';
import { faCircleDown } from '@fortawesome/free-regular-svg-icons';
import Offcanvas from 'react-bootstrap/Offcanvas';
const profileImage = require('../../assets/defaultprofile.svg').default;

const DataTables = ({ jobseekers, onSuspend }) => {
    // const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [profiles, setProfile] = useState([]);
    const [profiles2, setProfile2] = useState([]);
    const [profiles3, setProfile3] = useState([]);
    const [profiles4, setProfile4] = useState([]);

    const [isGridVisible, setGridVisible] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [uniqueCityStatuses, setCitizenshipStatuses] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [show, setShow] = useState(false);
    const [showrefere, setShowRefere] = useState(false);
    const handleClose = () => setShow(false);
    const handleCloserefere = () => setShowRefere(false);
    // const handleShow = () => setShow(true);
    const fetchUserData = async (userId) => {
        setLoading(true);
        try {
            const response = await apiService.getRequest(`/admin/jobseeker/${userId}`);
            if (response.success === true){
                setProfile(response.data);
                if(response.data.immigration_data.length !== 0){
                    setProfile2(response.data.immigration_data);
                }
                if(response.data.emergency_contact.length !== 0){
                    setProfile3(response.data.emergency_contact);
                }
                if(response.data.referral_info.length !== 0){
                    setProfile4(response.data.referral_info);
                }
            }else{
                
            }
        } catch (error) {
            console.error("Error fetching user data", error);
        }finally {
            setLoading(false);
        }
    };
    const handleShow = (userId) => {
        setShow(true); // Set the ID
        fetchUserData(userId); // Fetch user data
    };

    
    const toggleGrid = () => {
        setGridVisible((prev) => !prev);
    };

    const [filters, setFilters] = useState([{ operator: 'And', column: 'userName', operatorType: 'contains', value: '' }]);


    const combineOptions = [
        { value: 'And', label: 'And' },
        { value: 'Or', label: 'Or' },
    ];
    useEffect(() => {
        const fetchData = async () => {
            const response = await apiService.postRequest('generalsettings-data');
            if (response.success) {
                setCitizenshipStatuses(response.citizenship_statuses);
            }
        };
        fetchData();
    }, []);
    const rows = jobseekers.map((datas) => ({
        id: datas.id,
        formatid: datas.format_id,
        userName: datas.name,
        userImage: datas.profile || profileImage,
        status: datas.status_details.label,
        members: 4,
        cityStatus:datas.citizenship_status,
        city:datas.city,
        state:datas.state_name,
        refereName:datas.referrer_name,
        contactPhone: datas.phone_number,
        contactEmail: datas.email,
        date: datas.created_at,
        suspended: datas.suspended,
        colors: datas.status_details.fg_color,
        bgcolors: datas.status_details.bg_color,
    }));

    const handleFilterChange = (index, field, value) => {
        const newFilters = [...filters];
        // Reset value if changing the column type
        if (field === 'column') {
            newFilters[index].value = newFilters[index].column === 'date' ? [null, null] : '';
        }
        newFilters[index][field] = value;
        setFilters(newFilters);
    };

    const addFilter = () => {
        setFilters([...filters, { operator: 'And', column: 'userName', operatorType: 'contains', value: '' }]);
    };

    const removeFilter = (index) => {
        setFilters(filters.filter((_, i) => i !== index));
    };
    const operatorOptions = [
        { value: 'contains', label: 'contains' },
        { value: 'doesNotContain', label: 'does not contain' },
        { value: 'startsWith', label: 'starts with' },
        { value: 'endsWith', label: 'ends with' },
        { value: 'is', label: 'is' },
        { value: 'isNot', label: 'is not' },
        { value: 'isEmpty', label: 'is empty' },
        { value: 'isNotEmpty', label: 'is not empty' },
    ];
    const getOperatorOptions = (column) => {
        if (column === 'cityStatus') {
            return [
                { value: 'contains', label: 'contains' },
                { value: 'doesNotContain', label: 'does not contain' },
                { value: 'startsWith', label: 'starts with' },
                { value: 'endsWith', label: 'ends with' },
                { value: 'is', label: 'is' },
                { value: 'isNot', label: 'is not' },
                { value: 'isOneOf', label: 'is one of' },
                { value: 'isNotOneOf', label: 'is not one of' },
                { value: 'isEmpty', label: 'is empty' },
                { value: 'isNotEmpty', label: 'is not empty' },
            ];
        }
        return operatorOptions; // Default options
    };
    
    const filteredRows = rows.filter((row) =>
        filters.reduce((acc, filter, index) => {
            const rowValue = row[filter.column] ? row[filter.column].toString() : '';
            let matches = false;
    
            if (filter.column === 'date' && Array.isArray(filter.value) && filter.value.length === 2) {
                const [startDate, endDate] = filter.value;
                const rowDate = new Date(row.date);
                matches = (!startDate || rowDate >= new Date(startDate)) && (!endDate || rowDate <= new Date(endDate));
            } else if (filter.column === 'cityStatus') {
                switch (filter.operatorType) {
                    case 'is':
                        matches = rowValue === filter.value;
                        break;
                    case 'isNot':
                        matches = rowValue !== filter.value;
                        break;
                    case 'contains':
                        matches = rowValue.toLowerCase().includes(filter.value.toLowerCase());
                        break;
                    case 'doesNotContain':
                        matches = !rowValue.toLowerCase().includes(filter.value.toLowerCase());
                        break;
                    case 'startsWith':
                        matches = rowValue.toLowerCase().startsWith(filter.value.toLowerCase());
                        break;
                    case 'endsWith':
                        matches = rowValue.toLowerCase().endsWith(filter.value.toLowerCase());
                        break;
                    case 'isEmpty':
                        matches = rowValue === '';
                        break;
                    case 'isNotEmpty':
                        matches = rowValue !== '';
                        break;
                    case 'isOneOf': // Check if value is one of the provided options
                        matches = filter.value.split(',').includes(rowValue);
                        break;
                    case 'isNotOneOf': // Check if value is not one of the provided options
                        matches = !filter.value.split(',').includes(rowValue);
                        break;
                    default:
                        matches = false;
                }
            } else {
                // General filter logic for other columns
                switch (filter.operatorType) {
                    case 'contains':
                        matches = rowValue.toLowerCase().includes(filter.value.toLowerCase());
                        break;
                    case 'doesNotContain':
                        matches = !rowValue.toLowerCase().includes(filter.value.toLowerCase());
                        break;
                    case 'startsWith':
                        matches = rowValue.toLowerCase().startsWith(filter.value.toLowerCase());
                        break;
                    case 'endsWith':
                        matches = rowValue.toLowerCase().endsWith(filter.value.toLowerCase());
                        break;
                    case 'is':
                        matches = rowValue === filter.value;
                        break;
                    case 'isNot':
                        matches = rowValue !== filter.value;
                        break;
                    case 'isEmpty':
                        matches = rowValue === '';
                        break;
                    case 'isNotEmpty':
                        matches = rowValue !== '';
                        break;
                    default:
                        matches = false;
                }
            }
    
            return index === 0 ? matches : (filter.operator === 'And' ? acc && matches : acc || matches);
        }, true)
    );
    const paginatedRows = filteredRows.slice(currentPage * pageSize, currentPage * pageSize + pageSize);

    const downloadCSV = () => {
        const csvData = filteredRows.map(row => ({
            'Jobseeker No': row.formatid,
            'User Name': row.userName,
            'Citizenship Status': row.cityStatus,
            'Email': row.contactEmail,
            'Phone Number': row.contactPhone,
            'State': row.state,
            'City': row.city,
            'Referrer Name': row.refereName,
        }));
        const csv = Papa.unparse(csvData); // Parse the data into CSV
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, 'filtered_jobseekers.csv'); // Trigger file download
    };

    const columns = [
        {
            field: 'formatid',
            headerName: 'Jobseeker No',
            width: 250,
            renderCell: (params) => (
                <div className='d-flex align-items-center h-100'>
                    <span className='cursetespoint' style={{color:"#C42B7D"}} onClick={() => handleShow(params.row.id)}>{params.row.formatid}</span>
                </div>
            ),
            filterable: true
        },
        {
            field: 'userName',
            headerName: 'User',
            width: 250,
            renderCell: (params) => (
                <div className='d-flex align-items-center h-100'>
                    <Avatar src={params.row.userImage} alt={params.row.userName} />
                    <span className='ms-3'>{params.row.userName}</span>
                </div>
            ),
            filterable: true
        },
        {
            field: 'cityStatus',
            headerName: 'Citizenship Status',
            width: 200,
            filterable: true,
        },
        // {
        //     field: 'status',
        //     headerName: 'Status',
        //     width: 150,
        //     filterable: true,
        //     renderCell: (params) => (
        //         <div className='d-flex align-items-center h-100'>
        //             <span style={{ color: params.row.colors, background: params.row.bgcolors }} className='bg_dynaspangroupjsa'>{params.value}</span>
        //         </div>
        //     )
        // },
        // {
        //     field: 'members',
        //     headerName: 'Members',
        //     width: 220,
        //     filterable: true,
        //     renderCell: () => (
        //         <div className='d-flex align-items-center gap-2 h-100'>
        //             <Avatar src={profileImage} alt="Member" />
        //             <Avatar src={profileImage} alt="Member" />
        //             <Avatar src={profileImage} alt="Member" />
        //             <Avatar src={profileImage} alt="Member" />
        //         </div>
        //     )
        // },
        {
            field: 'contactEmail',
            headerName: 'Email',
            width: 250,
            filterable: true,
            renderCell: (params) => (
                <Box className="linklistview h-100 d-flex align-items-center">
                    <Typography><span><a href={`mailto:${params.row.contactEmail}`} target="_blank" rel="noopener noreferrer">{params.row.contactEmail}</a></span></Typography>
                </Box>
            )
        },
        {
            field: 'contactPhone',
            headerName: 'Phone',
            width: 250,
            filterable: true,
            renderCell: (params) => (
                <Box className="linklistview h-100 d-flex align-items-center">
                    <Typography><span><a href={`tel:${params.row.contactPhone}`} target="_blank" rel="noopener noreferrer">{params.row.contactPhone}</a></span></Typography>
                </Box>
            )
        },
        {
            field: 'state',
            headerName: 'State',
            width: 200,
            filterable: true,
        },
        {
            field: 'city',
            headerName: 'City',
            width: 200,
            filterable: true,
        },
        {
            field: 'refereName',
            headerName: 'Referrer Name',
            width: 200,
            renderCell: (params) => (
                <div className='d-flex align-items-center h-100'>
                    <span className='cursetespoint' onClick={() => setShowRefere(true)}>{params.row.refereName}</span>
                </div>
            ),
            filterable: true,
        },

        // {
        //     field: 'date',
        //     headerName: 'Date',
        //     width: 200,
        //     filterable: true,
        // },
        // {
        //     field: 'action',
        //     headerName: 'Action',
        //     width: 200,
        //     renderCell: (params) => (
        //         <div className='d-flex justify-content-center align-items-center h-100'>
        //             <button
        //                 className='suspent_btn'
        //                 onClick={() => onSuspend(params.row.id, params.row.suspended === 1)}
        //             >
        //                 {params.row.suspended === 0 ? "Suspend" : "UnSuspend"}
        //             </button>
        //         </div>
        //     )
        // },
    ];

    return (
        <Box sx={{ height: 650, width: '100%' }} className="datatable_jobpagi">
            {isGridVisible && (
            <Box sx={{ mb: 2 }} className="my-4">
                {filters.map((filter, index) => (
                    <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        {index > 0 && (
                            <select
                                label="Combine"
                                value={filter.operator}
                                onChange={(e) => handleFilterChange(index, 'operator', e.target.value)}
                                sx={{ mr: 1, minWidth: 80 }}
                            >
                                {combineOptions.map((option) => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                ))}
                            </select>
                        )}
                        <TextField
                            select
                            label="Column"
                            value={filter.column}
                            onChange={(e) => handleFilterChange(index, 'column', e.target.value)}
                            sx={{ mr: 1, minWidth: 120 }}
                        >
                            <MenuItem value="userName">User Name</MenuItem>
                            <MenuItem value="formatid">Jobseeker No</MenuItem>
                            <MenuItem value="cityStatus">Citizenship Status</MenuItem>
                            <MenuItem value="contactEmail">Contact Email</MenuItem>
                            <MenuItem value="contactPhone">Phone Number</MenuItem>
                            <MenuItem value="state">State</MenuItem>
                            <MenuItem value="city">City</MenuItem>
                            <MenuItem value="refereName">Referrer Name</MenuItem>
                            {/* <MenuItem value="date">Date</MenuItem> */}
                        </TextField>
                        <TextField
                            select
                            label="Operator"
                            value={filter.operatorType}
                            onChange={(e) => handleFilterChange(index, 'operatorType', e.target.value)}
                            sx={{ mr: 1, minWidth: 120 }}
                        >
                            {getOperatorOptions(filter.column).map((option) => (
                                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                            ))}
                        </TextField>
                        {filter.column === 'date' ? (
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            className='filterrages'
                                            label="Start Date"
                                            value={filter.value[0] || null}
                                            onChange={(newValue) => handleFilterChange(index, 'value', [newValue, filter.value[1]])}
                                        />
                                        <DatePicker
                                            label="End Date"
                                            className='filterrages'
                                            value={filter.value[1] || null}
                                            onChange={(newValue) => handleFilterChange(index, 'value', [filter.value[0], newValue])}
                                        />
                                    </LocalizationProvider>
                                </Box>
                            ) : filter.column === 'cityStatus' ? (
                                <select
                                    label="Value"
                                    value={filter.value}
                                    onChange={(e) => handleFilterChange(index, 'value', e.target.value)}
                                    sx={{ mr: 1, minWidth: 120 }}
                                >
                                    <option value="" disabled>Select Citizenship Status</option>
                                    {uniqueCityStatuses.map((status) => (
                                    <option key={status.id} value={status.status}>
                                        {status.status}
                                    </option>
                                    ))}
                                </select>
                            ) : (
                                <input
                                    className='value_inputfilter'
                                    placeholder='Value'
                                    label="Value"
                                    value={filter.value}
                                    onChange={(e) => handleFilterChange(index, 'value', e.target.value)}
                                />
                            )}
                        <button className='filterremovebtn' onClick={() => removeFilter(index)}>Remove</button>
                    </Box>
                ))}
                <button className='filteraddbtn' onClick={addFilter}>Add Filter</button>
            </Box>)}
            <Grid size={{xs:12}} container justifyContent="space-between">
            <button className='show_filterbtn' onClick={toggleGrid}>
                {isGridVisible ?<> Hide Filter <FontAwesomeIcon icon={faFilterCircleXmark} /> </> :<> Show Filter <FontAwesomeIcon icon={faFilter} /> </>}
            </button>
            <button className='download_csvbtndatatable' onClick={downloadCSV}>Download CSV<FontAwesomeIcon className='ps-2' icon={faCircleDown} /></button>
            </Grid>
            <DataGrid
                rows={paginatedRows}
                columns={columns}
                pageSize={pageSize}
                disableSelectionOnClick
                components={{ Toolbar: GridToolbar }}
            />
            <div className='d-flex flex-column my-3 pagination_datagrid'>
                <div className='d-flex justify-content-end align-items-center'>
                    <label>No. of Rows</label>
                    <select
                        label="Rows per page"
                        value={pageSize}
                        onChange={(e) => setPageSize(Number(e.target.value))}
                        sx={{ mr: 2 }}
                    >
                        {[5, 10, 20, 50].map((size) => (
                            <option key={size} value={size}>{size}</option>
                        ))}
                    </select>
                </div>

                <div className="d-flex justify-content-center my-3">
                    <button
                        className='nexter_btns'
                        variant="contained"
                        onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 0))}
                        disabled={currentPage === 0}
                    >
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </button>

                    {/* Page number buttons */}
                    <div className="d-flex align-items-center mx-2">
                        {Array.from({ length: Math.ceil(filteredRows.length / pageSize) }, (_, index) => (
                            <button
                                key={index}
                                className={`page-btn ${currentPage === index ? 'active' : ''}`}
                                onClick={() => setCurrentPage(index)}
                            >
                                {index + 1}
                            </button>
                        ))}
                    </div>

                    <button
                        className='nexter_btns'
                        variant="contained"
                        onClick={() => setCurrentPage((prev) => Math.min(prev + 1, Math.ceil(filteredRows.length / pageSize) - 1))}
                        disabled={currentPage >= Math.ceil(filteredRows.length / pageSize) - 1}
                    >
                        <FontAwesomeIcon icon={faChevronRight} />
                    </button>
                </div>
            </div>

                <Offcanvas show={show} onHide={handleClose} placement='end' className="ofcanvas_widthsetters">
                    <Offcanvas.Header closeButton>
                    <Offcanvas.Title></Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Grid size={{lg:12}} container alignItems="center" justifyContent="center">
                            <Grid size={{md:12,xs:12}} container alignItems="flex-start" justifyContent="space-between" flexDirection="row" className="jobseekerhead">
                                <h3>Jobseekers Profile</h3>
                            </Grid>
                            {loading ? 
                            <div className='d-flex justify-content-center w-100'>
                                <img style={{ width: "100px" }} src={require('../../assets/pinkloader.gif')} alt='Loading...' />
                            </div> :
                                <Grid size={{md:11,xs:12}} container justifyContent="space-between" className="jobseekers_profilealls my-4">
                                    <Grid size={{xs:12}} container>
                                        <h4>Personal Information</h4>
                                    </Grid>
                                    <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>First Name</span>
                                        <p>{profiles.first_name}</p>
                                    </Grid>
                                    <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>Last Name</span>
                                        <p>{profiles.last_name}</p>
                                    </Grid>
                                    <Grid size={{sm:12,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>Email Id</span>
                                        <p>{profiles.email}</p>
                                    </Grid>
                                    <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>Date of Birth</span>
                                        <p>{profiles.dob}</p>
                                    </Grid>
                                    <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>Gender</span>
                                        <p className='capssets'>{profiles.gender}</p>
                                    </Grid>
                                    <Grid size={{sm:12,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>Address</span>
                                        <p className='capssets'>{profiles.address}</p>
                                    </Grid>
                                    <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>City</span>
                                        <p className='capssets'>{profiles.city}</p>
                                    </Grid>
                                    <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>State</span>
                                        <p className='capssets'>{profiles.state}</p>
                                    </Grid>
                                    <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>Zip Code</span>
                                        <p>{profiles.zipcode}</p>
                                    </Grid>
                                    <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>Phone Number</span>
                                        <p>{profiles.phone_number}</p>
                                    </Grid>
                                    <Grid size={{sm:12,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>Why do you want to enroll with us?</span>
                                        <p className='capssets'>{profiles.why_enroll}</p>
                                    </Grid>
                                    {profiles2 && profiles2.length === 0 ? 
                                    "":<>
                                    <Grid size={{xs:12}} container>
                                        <h4>Immigration Information</h4>
                                    </Grid>
                                    <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>Citizenship Status</span>
                                        <p className='capssets'>{profiles2.citizenship_status}</p>
                                    </Grid>
                                    <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>Are you waiting for your EAD Card to Arrive?</span>
                                        <p className='capssets'>{profiles2.awaiting_ead_card || "No data"}</p>
                                    </Grid>
                                    <Grid size={{sm:12,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>When did you apply for your EAD Card?</span>
                                        <p className='capssets'>{profiles2.ead_card_applied_date || "No data"}</p>
                                    </Grid>
                                    <Grid size={{sm:12,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>Explain your citizenship status</span>
                                        <p className='capssets'>{profiles2.citizenship_explanation}</p>
                                    </Grid>
                                    <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>Are you legally authorized to work in United States ?</span>
                                        <p className='capssets'>{profiles2.legally_authorized_to_work}</p>
                                    </Grid>
                                    <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>When will your work authorization expire ?</span>
                                        <p className='capssets'>{profiles2.work_authorization_expiry || "No data"}</p>
                                    </Grid>
                                    <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>Which year was your SSN Issued ?</span>
                                        <p className='capssets'>{profiles2.ssn_issue_year || "No data"}</p>
                                    </Grid>
                                    <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>Upload Most Recent I94 Copy</span>
                                        <a href={`${profiles2.i94_copy}`} target="_blank" rel="noopener noreferrer">View</a>
                                    </Grid>
                                    <Grid size={{sm:12,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>I hereby authorize agents of TekPossible Consulting to access and verify the authenticity of my uploaded I-94 document through the official I-94 website (https://i94.cbp.dhs.gov/I94). I understand that this verification will be conducted in accordance with applicable laws and regulations.</span>
                                        <p>Yes</p>
                                    </Grid>
                                    <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>Upload Proof of Work Authorization</span>
                                        <a href={`${profiles2.proof_of_work_authorization}`} target="_blank" rel="noopener noreferrer">View</a>
                                    </Grid>
                                    <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>Upload Profile Picture</span>
                                        <a href={`${profiles2.profile}`} target="_blank" rel="noopener noreferrer">View</a>
                                    </Grid>
                                    </>}
                                    {profiles3 && profiles3.length === 0 ?
                                    "":<>
                                    <Grid size={{sm:12,xs:12}} container>
                                        <h4>Emergency Contact</h4>
                                    </Grid>
                                    <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>First Name</span>
                                        <p className='capssets'>{profiles3.first_name}</p>
                                    </Grid>
                                    <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>Last Name</span>
                                        <p className='capssets'>{profiles3.last_name}</p>
                                    </Grid>
                                    <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>Phone Number</span>
                                        <p className='capssets'>{profiles3.phone_number}</p>
                                    </Grid>
                                    <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>Relationship</span>
                                        <p className='capssets'>{profiles3.relation_type}</p>
                                    </Grid>
                                    </>}
                                    {profiles4 && profiles4.length === 0 ? 
                                    "":<>
                                    <Grid size={{sm:12,xs:12}} container>
                                        <h4>Referral Information</h4>
                                    </Grid>
                                    <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>Referrer Phone Number</span>
                                        <p className='capssets'>{profiles4.referral_phone_number}</p>
                                    </Grid>
                                    <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>Referrer Name</span>
                                        <p className='capssets'>{profiles4.referrer_name}</p>
                                    </Grid>
                                    <Grid size={{sm:12,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>How do you know the referrer ?</span>
                                        <p className='capssets'>{profiles4.referrer_relationship}</p>
                                    </Grid>
                                    </>}
                                </Grid>
                            }
                        </Grid>
                    </Offcanvas.Body>
                </Offcanvas>

                <Offcanvas show={showrefere} onHide={handleCloserefere} placement='end' className="ofcanvas_widthsetters">
                    <Offcanvas.Header closeButton>
                    <Offcanvas.Title></Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Grid size={{lg:12}} container alignItems="center" justifyContent="center">
                            <Grid size={{md:12,xs:12}} container alignItems="flex-start" justifyContent="space-between" flexDirection="row" className="jobseekerhead">
                                <h3>Referrer Detail</h3>
                            </Grid>
                            <Grid size={{md:11,xs:12}} container justifyContent="space-between" className="jobseekers_profilealls my-4">
                                {/* <Grid size={{xs:12}} container>
                                    <h4>Personal Information</h4>
                                </Grid> */}
                                <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                    <span>Member ID</span>
                                    <p>#MEMBER0000</p>
                                </Grid>
                                <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                    <span>Full Name</span>
                                    <p>Example Name</p>
                                </Grid>
                                <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                    <span>Phone Number</span>
                                    <p>(123) 456-7890</p>
                                </Grid>
                                <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                    <span>Email Id</span>
                                    <p>example@gamil.com</p>
                                </Grid>
                                <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                    <span>State</span>
                                    <p className='capssets'>Arkansas</p>
                                </Grid>
                                <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                    <span>City</span>
                                    <p className='capssets'>Alexander</p>
                                </Grid>
                                <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                    <span>Level</span>
                                    <p>0</p>
                                </Grid>
                                <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                    <span>Total Referrals</span>
                                    <p>0</p>
                                </Grid>
                                <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                    <span>Reffered By</span>
                                    <p>Name</p>
                                </Grid>
                                <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                    <span>Phone Number</span>
                                    <p>(123)456-7890</p>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Offcanvas.Body>
                </Offcanvas>
        </Box>
    );
};

export default DataTables;
