import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid2';
import "./usergroup.css";
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled, Switch } from '@mui/material';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { apiService } from '../../services/apiService';
import { useNavigate } from 'react-router-dom';
function UserGroupAdd() {
    const [key, setKey] = useState('Navigation');
    const navigate = useNavigate();
    const [selectedIds, setSelectedIds] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [inputError, setInputError] = useState('');
    const [errorsmessage, setErrorMessage] = useState('');
    const [submitloader, setSubmitloader] = useState(false);
    const [tabData, setTabData] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const AntSwitch = styled(Switch)(({ theme }) => ({
      width: 40,
      height: 25,
      padding: 0,
      display: 'flex',
      '&:active': {
        '& .MuiSwitch-thumb': {
          width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
          transform: 'translateX(9px)',
        },
      },
      '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
          transform: 'translateX(16px)',
          color: '#fff',
          '& + .MuiSwitch-track': {
            opacity: 1,
            backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#51cc64',
          },
        },
      },
      '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 21,
        height: 21,
        borderRadius: 100,
        transition: theme.transitions.create(['width'], {
          duration: 200,
        }),
      },
      '& .MuiSwitch-track': {
        borderRadius: 100,
        opacity: 1,
        backgroundColor:
          theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
      },
    }));

      useEffect(() => {
        const fetchTabData = async () => {
          try {
            const response = await apiService.getRequest('admin/permissions'); // Replace with your actual API endpoint
            setTabData(response.data);
          } catch (error) {
            console.error('Error fetching tab data:', error);
          }
        };
    
        fetchTabData();
      }, []);
    
      // Check if the data is loaded
      
  

    // Function to handle switch change and update selected IDs
    const handleSwitchChange = (e, id) => {
        const isChecked = e.target.checked;

        setSelectedIds((prevSelectedIds) => {
        if (isChecked) {
            return [...prevSelectedIds, id];
        } else {
            return prevSelectedIds.filter((selectedId) => selectedId !== id);
        }
        });
    };

  // Function to handle input field changes
  const handleInputChange = (e) => {
    setInputValue(e.target.value); 
    setInputError('');
  };

  
  const handleSave = async () => {
    if (!inputValue) {
        setInputError('This field is required');
        setErrorMessage('Please fix the errors before proceeding.');
        return;
    }
      const dataToSend = {
        name: inputValue,
        permissions: selectedIds,
      };
      setSubmitloader(true);
      try {
        
        const response = await apiService.postRequest('/admin/roles', dataToSend);

        // Handle success or failure here
        if (response.success) {
            navigate('/user/group');
        } else {
          setErrorMessage('Registration failed. Please try again.');
        }
      } catch (error) {
        console.error('Error making API request:', error);
      }finally {
        setSubmitloader(false); // Stop loader
    }
    
  };
  const renderTabContent = (tabKey) => {
    if (!tabData) {
        return <img style={{ width: "100px" }} src={require('../../assets/pinkloader.gif')} alt='Loading...' />;
    }

    // Filter items based on search term
    const filteredItems = tabData[tabKey].filter((item) => 
        item.display_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    if (filteredItems.length === 0) {
        return (
            <div className='d-flex justify-content-center'>
                <span>No Data to Display</span>
            </div>
        );
    }
    return filteredItems.map((item) => (
        <Grid size={{sm: 11, xs: 11}} key={item.id} container className="mb-4">
            <Grid size={{sm: 6, xs: 10}} container flexDirection="column">
                <span className='page_span'>{item.icon && <img src={item.icon} alt=''/>}{item.display_name}</span>
                {item.children.length > 0 ? (
                    item.children.map((innerids) => (
                        <small key={innerids.id}><input type='checkbox' onChange={(e) => handleSwitchChange(e, innerids.id)} checked={selectedIds.includes(innerids.id)}/>{innerids.display_name}</small>
                    ))): (
                        ""
                    )}
            </Grid>
            <Grid size={{sm: 2, xs: 2}} container justifyContent="center">
                <AntSwitch 
                    onChange={(e) => handleSwitchChange(e, item.id)} 
                    checked={selectedIds.includes(item.id)} // Ensure toggle reflects selected IDs
                    inputProps={{ 'aria-label': 'ant design' }} 
                />
            </Grid>
        </Grid>
    ));
};

    const mappedTabData = Object.keys(tabData || {}).map((tabKey) => ({
        eventKey: tabKey,
        title: tabKey,
        content: renderTabContent(tabKey),
    }));
  return (
    <div className='container-fluid px-0'>
        <div className='content_container'>
            <Grid size={{lg:12}} container justifyContent="center" className="main_jobseeker">
                <Grid size={{lg:12}} container alignItems="center">
                    <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead">
                        <h3>User</h3>
                        <span><b onClick={()=>navigate("/dashboard")}>Dashboard</b> <FontAwesomeIcon className='angleright' icon={faAngleRight} /> <b onClick={()=>navigate("/user/group")}>User</b> <FontAwesomeIcon className='angleright' icon={faAngleRight} /> <b onClick={()=>navigate("/user/group")}>Group</b> <FontAwesomeIcon className='angleright' icon={faAngleRight} /> Add Group</span>
                    </Grid>
                    <Grid size={{xs:12}} container className="editgrouphead my-4">
                        <h2>Add Group</h2>
                    </Grid>
                </Grid>
                <Grid size={{xs:12}} container justifyContent="center">
                    <Grid size={{md:11,xs:12}} container>
                        <Grid size={{md:5,xs:12}} container flexDirection="column" className="input_groupname">
                            <span>Group Name</span>
                            <input type='text' placeholder='Group Name' onChange={handleInputChange} value={inputValue}/>
                            {inputError && <p style={{color:"red"}}>{inputError}</p>}
                        </Grid>
                    </Grid>
                    <Grid size={{sm:11,xs:12}} container flexDirection="column" justifyContent="center">
                    {mappedTabData.length <= 0 ?
                            <div className='d-flex justify-content-center w-100'>
                                <img style={{ width: "100px" }} src={require('../../assets/pinkloader.gif')} alt='Loading...' />
                            </div> :
                        <Tabs
                            activeKey={key}
                            onSelect={(k) => setKey(k)}
                            id="justify-tab-example"
                            className=" my-4 dashboard_tabs"
                            >
                            {mappedTabData.map((tab) => (
                                <Tab key={tab.eventKey} eventKey={tab.eventKey} title={tab.title}>
                                    <Grid size={{md:12,xs:12}} container justifyContent="center" className="white_editgroup mt-2 mb-4">
                                        <Grid size={{sm:11, xs:12}} container justifyContent="flex-end" className="mb-4 d-none">
                                            <input
                                                className='search_perminewsec'
                                                type="text" 
                                                placeholder="Search permissions..." 
                                                value={searchTerm} 
                                                onChange={(e) => setSearchTerm(e.target.value)} 
                                            />
                                        </Grid>
                                        <Grid size={{xs:11}} container className="mb-4">
                                            <Grid size={{sm:6,xs:6}} container>
                                                <h6>Title</h6>
                                            </Grid>
                                            <Grid size={{sm:2,xs:6}} container className="justify-content-md-center justify-content-end">
                                                <h6>Permissions</h6>
                                            </Grid>
                                        </Grid>
                                        {tab.content}
                                    </Grid>
                                </Tab>
                            ))}
                        </Tabs>
                        }
                    </Grid>
                </Grid>
                <Grid size={{sm:11}} container className="ps-md-5 gap-md-5 gap-2 mb-4">
                {errorsmessage && <p style={{ color: 'red',width:"100%" }}>{errorsmessage}</p>}
                    <button onClick={() =>{navigate("/user/group")}} className='cancel_btnbonly'>Cancel</button>
                    <button onClick={handleSave} className='save_btnbonly'>{submitloader ? <img src={require('../../assets/whiteloader.gif')} alt=''/> : 'Save'}</button>
                </Grid>
            </Grid>
        </div>
    </div>
  )
}

export default UserGroupAdd
