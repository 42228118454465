import React, { useCallback, useEffect, useState } from 'react'
import Grid from '@mui/material/Grid2';
import "./admin.css";
import "./rolesset.css";
import { faAngleRight, faChevronLeft, faChevronRight, faEllipsisVertical, faGrip, faList, faMagnifyingGlass, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { styled, Switch } from '@mui/material';
import { faPenToSquare, faTrashCan } from '@fortawesome/free-regular-svg-icons';
import AdminRoletabs from '../../components/common/AdminRoletabs';
import { useNavigate } from 'react-router-dom';
import { apiService } from '../../services/apiService';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
const profileImage = require('../../assets/defaultprofile.svg').default;
function UserGroup() {
    const [show, setShow] = useState(false);
    const [selectedGroupId, setSelectedGroupId] = useState(null);

    const handleClose = () => setShow(false);
    const handleShow = (groupId) => {
        setSelectedGroupId(groupId);
        setShow(true);
    };
    const [listview, setListview] = useState(false);
    const [groupdata, setGroupData] = useState([]);
    const navigate = useNavigate();
    const [paginatedata, setPaginateData] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState("");
    const [loading, setLoading] = useState(false);
    const AntSwitch = styled(Switch)(({ theme }) => ({
        width: 40,
        height: 25,
        padding: 0,
        display: 'flex',
        '&:active': {
          '& .MuiSwitch-thumb': {
            width: 15,
          },
          '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
          },
        },
        '& .MuiSwitch-switchBase': {
          padding: 2,
          '&.Mui-checked': {
            transform: 'translateX(15px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
              opacity: 1,
              backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#51cc64',
            },
          },
        },
        '& .MuiSwitch-thumb': {
          boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
          width: 20,
          height: 20,
          borderRadius: 100,
          transition: theme.transitions.create(['width'], {
            duration: 200,
          }),
        },
        '& .MuiSwitch-track': {
          borderRadius: 100,
          opacity: 1,
          backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
          boxSizing: 'border-box',
        },
    }));

    const fetchTabData = useCallback(async (params = {}) => {
        setLoading(true);
        try {
            const queryString = new URLSearchParams(params).toString();
            const response = await apiService.getRequest(`admin/roles?${queryString}`);
            
            setGroupData(response.data);
            setPaginateData(response.meta);
        } catch (error) {
            console.error('Error fetching tab data:', error);
        }finally {
            setLoading(false);
        }
    }, []);

    // Handle page change
    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
        fetchTabData({ page: newPage });
    };

    // Handle search query change
    const handleSearch = () => {
        setCurrentPage(1);
        fetchTabData({ search: searchQuery });
    };

    // Fetch initial data on component mount
    useEffect(() => {
        fetchTabData();
    }, [fetchTabData]);

    // Function to handle delete
    const handleDelete = async () => {
        try {
            const response = await apiService.deleteRequest(`admin/roles/${selectedGroupId}`);
            setGroupData(prevGroupData => prevGroupData.filter(group => group.id !== selectedGroupId));
            if (response && response.success === true) {
                toast("Group deleted successfully");
            }
        } catch (error) {
            console.error('Error deleting group:', error);
            toast.error("Failed to delete group");
        }
        handleClose();
    };
    
    const handleStatusToggle = async (groupId, currentStatus) => {
        const newStatus = currentStatus === 1 ? 0 : 1;
        
        // Optimistically update the UI before calling the API
        setGroupData(prevGroupData =>
          prevGroupData.map(group =>
            group.id === groupId ? { ...group, status: newStatus } : group
          )
        );
      
        try {
          const response = await apiService.patchRequest(`admin/roles/${groupId}/status`, {
            status: newStatus,
          });
          
          if (response.success) {
            toast(`Group status updated to ${newStatus === 1 ? 'Active' : 'Inactive'}`);
            fetchTabData();
          } else {
            // Revert back the status if the API call fails
            setGroupData(prevGroupData =>
              prevGroupData.map(group =>
                group.id === groupId ? { ...group, status: currentStatus } : group
              )
            );
            toast.error('Failed to update group status');
          }
        } catch (error) {
          console.error('Error updating group status:', error);
          setGroupData(prevGroupData =>
            prevGroupData.map(group =>
              group.id === groupId ? { ...group, status: currentStatus } : group
            )
          );
          toast.error('Failed to update group status');
        }
    };
    

        const rows = groupdata.map((datas) => ({
            id: datas.id,
            userName: datas.name,
            userImage: datas.profile || profileImage,
            contactPhone: datas.phone_number,
            contactEmail: datas.email,
            status: datas.status,
            statusstring: datas.status_string,
            users: datas.users.map(user => ({
                display_name: user.name,
                memberImage: user.profile || profileImage,
            })),
        }));
      const columns = [
        {
            field: 'userName',
            headerName: 'Group Name',
            width: 350,
            renderCell: (params) => (
                <div className='d-flex align-items-center h-100'>
                    <Avatar src={params.row.userImage} alt={params.row.userName} />
                    <span className='ms-3'>{params.row.userName}</span>
                </div>
            ),
            filterable: true
        },
        {
            field: 'Members',
            headerName: 'Members',
            width: 350,
            filterable: false,
            renderCell: (params) => {
                const users = params.row.users || []; // Default to an empty array if users is undefined

                return (
                    <Box display="flex" alignItems="center" height="100%">
                        {Array.isArray(users) && users.length > 0 ? (
                            users.slice(0, 4).map((role,index) => (
                                <div key={index} style={{ marginRight: 10 }}>
                                    <img
                                        src={role.memberImage}
                                        alt={role.display_name}
                                        style={{
                                            width: 40, // Set width as needed
                                            height: 40, // Set height as needed
                                            borderRadius: '50%', // Make it circular
                                        }}
                                    />
                                </div>
                            ))
                        ) : (
                            <Typography color="textSecondary">No roles</Typography>
                        )}
                        {users.length > 4 && (
                            <Typography variant="body2" color="textSecondary" style={{ marginLeft: 5 }}>
                                +{users.length - 4} more
                            </Typography>
                        )}
                    </Box>
                );
            },
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 250,
            filterable: true,
            renderCell: (params) => (
                <Box className="h-100 d-flex align-items-center">
                    <small className='activestatus align-items-center gap-3 d-flex w-100'>
                        <AntSwitch
                        checked={params.row.status === 1}
                        onChange={() => handleStatusToggle(params.row.id, params.row.status)}
                        inputProps={{ 'aria-label': 'ant design' }}
                        />
                    <small style={{color:params.row.statusstring === 'Active' ? "#51cc64" : "red"}}>{params.row.statusstring}</small></small>
                </Box>
            )
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 200,
            renderCell: (params) => (
                <Grid size={{ xs: 12 }} container alignItems="center" justifyContent="flex-start" className="gap-md-3 h-100 gap-2">
                    <button className='editbtnroot' onClick={() => navigate(`/user/group-edit/${params.row.id}`)}>
                        <FontAwesomeIcon icon={faPenToSquare} />
                    </button>
                    {params.row.id !== 3 &&
                    <button className='trashbtnroot' onClick={() => handleShow(params.row.id)}>
                        <FontAwesomeIcon icon={faTrashCan} />
                    </button>
                    }
                </Grid>
            )
        },
    ];
  return (
    <div className='container-fluid px-0'>
        <div className='content_container'>
            <Grid size={{xs:12}} container className="main_jobseeker">
                <Grid size={{xs:12}} container alignItems="center">
                    <Grid size={{md:8,xs:12}} container flexDirection="column" className="jobseekerhead">
                        <h3>User</h3>
                        <span><b onClick={()=>navigate("/dashboard")}>Dashboard</b> <FontAwesomeIcon className='angleright' icon={faAngleRight} /> User <FontAwesomeIcon className='angleright' icon={faAngleRight} /> Group</span>
                    </Grid>
                    <Grid size={{md:4,xs:12}} container flexDirection="column">
                        <div className='d-flex jobseekersearch my-3'>
                            <div className='col-2'>
                                <FontAwesomeIcon className='iconcol' icon={faMagnifyingGlass} />
                            </div>
                            <div className='col-7'>
                                <input type='text' placeholder='search'
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}/>
                            </div>
                            <div className='col-3'>
                                <button onClick={handleSearch}>Search</button>
                            </div>
                        </div>
                    </Grid>
                    <Grid size={{md:12,xs:12}} container justifyContent="space-between" className="statusselectbtn my-4">
                        <Grid size={{sm:8,xs:12}} container>
                            <AdminRoletabs/>
                        </Grid>
                        <Grid container>
                            <button onClick={() =>{navigate("/user/group-add")}}>
                                <FontAwesomeIcon icon={faPlus} />
                            </button>
                            <button onClick={()=>{setListview(!listview)}}>
                                {listview ?
                                    <FontAwesomeIcon icon={faGrip} />:
                                    <FontAwesomeIcon icon={faList} />
                                }
                            </button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid size={{xs:12}} container>
                {loading ? 
                    <div className='d-flex justify-content-center w-100'>
                        <img style={{ width: "100px" }} src={require('../../assets/pinkloader.gif')} alt='Loading...' />
                    </div> :<>
                {!listview ?
                    <Grid size={{xs:12}} container className="mt-4">
                        {groupdata && groupdata.length===0 ?
                            <div className='d-flex justify-content-center w-100'>
                                <img style={{ width: "100px" }} src={require('../../assets/pinkloader.gif')} alt='Loading...' />
                            </div> : ""
                        }
                        {groupdata.map((data) => (
                        <Grid size={{lg:4,md:6,sm:6,xs:12}} key={data.id} container justifyContent="center">
                            <Grid size={{xs:11.5}} container justifyContent="center" className="rolesgroupgridmain">
                                <Grid size={{xs:12}} container justifyContent="center" alignItems="center" className="gridtop_menuergroup">
                                    <Grid size={{xs:10}} container alignItems="center">
                                    <OverlayTrigger
                                        placement="top" // You can change the position (top, bottom, left, right)
                                        overlay={
                                            <Tooltip id="tooltip-top">
                                            {data.name}
                                            </Tooltip>
                                        }
                                        >
                                        <h4>{data.name}</h4>
                                    </OverlayTrigger>
                                        
                                    </Grid>
                                    <Grid size={{xs:2}} container justifyContent="flex-end">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="success" >
                                                <FontAwesomeIcon icon={faEllipsisVertical} />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className='px-2 box_shadedropdown'>
                                                <button className='edit_dropdown' onClick={() => navigate(`/user/group-edit/${data.id}`)}><FontAwesomeIcon icon={faPenToSquare} className='icon_set' />Edit</button>
                                                {data.id !== 3 &&
                                                <button className='edit_dropdown' onClick={() => handleShow(data.id)}><FontAwesomeIcon icon={faTrashCan} className='icon_set' />Delete</button>
                                                }
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Grid>
                                    <Grid size={{xs:12}} container className="switchbtn_withtext" alignItems="center">
                                        <AntSwitch
                                            checked={data.status === 1}
                                            onChange={() => handleStatusToggle(data.id, data.status)}
                                            inputProps={{ 'aria-label': 'ant design' }}
                                        />
                                        <font>Status Team: <b>{data.status_string}</b></font>
                                    </Grid>
                                </Grid>
                                <Grid size={{xs:12}} container className="gropadmingridcont" alignContent="flex-start">
                                    <small>Teams</small>
                                    {data.users >= 0 ?
                                        <p>No Member to display</p>
                                    :""}
                                    {data && data.users.map((userdata) => (
                                        <Grid size={{xs:12}} key={userdata.id} container justifyContent="center" alignItems="center" className="gridtop_editbtn">
                                            <Grid size={{xs:10}} container alignItems="center">
                                                {userdata.profile.length === 0 ? 
                                                    <img src={require('../../assets/defaultprofile.svg').default} alt=''/>:
                                                    <img src={userdata.profile} alt='userdata.name'/>
                                                }
                                                <span>{userdata.name}</span>
                                            </Grid>
                                            <Grid size={{xs:2}} container justifyContent="center">
                                                <button onClick={() => navigate(`/user/member-edit/${userdata.id}`)}>Edit</button>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                        ))}
                    </Grid> :
                    <Box sx={{ height: 650, width: '100%' }} className="datatable_jobpagi">
                    <DataGrid
                            rows={rows}
                            columns={columns}
                            disableSelectionOnClick
                            components={{ Toolbar: GridToolbar }}
                        />
                    </Box>
                }
                </>}
                </Grid>
                <Grid size={{xs:12}} container justifyContent="center" className="mt-4 pagination_btn">
                    <button className='arrowpaginate'
                        onClick={() => handlePageChange(currentPage - 1)} 
                        disabled={currentPage === 1}>
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </button>
                    {Array.from({ length: paginatedata.last_page }, (_, index) => (
                        <button 
                            key={index} 
                            onClick={() => handlePageChange(index + 1)}
                            className={currentPage === index + 1 ? 'active' : ''}
                        >
                            {index + 1}
                        </button>
                    ))}
                    <button className='arrowpaginate'
                        onClick={() => handlePageChange(currentPage + 1)} 
                        disabled={currentPage === paginatedata.last_page}>
                        <FontAwesomeIcon icon={faChevronRight} />
                    </button>
                </Grid>
            </Grid>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Group Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this Group?</Modal.Body>
                <Modal.Footer>
                    <button className='modal_cancel' onClick={handleClose}>
                        Cancel
                    </button>
                    <button className='modal_delete' onClick={handleDelete}>
                        Delete
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    </div>
  )
}

export default UserGroup
