import React, { useCallback, useEffect, useState } from 'react'
import Grid from '@mui/material/Grid2';
import { faAngleRight, faChevronLeft, faChevronRight, faHandshakeSimpleSlash, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import "./knowledge.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { faCalendar, faEye, faHandshake, faUser } from '@fortawesome/free-regular-svg-icons';
import { Rating } from '@mui/material';
import { apiService } from '../../services/apiService';
import KnowledgeCategory from '../../components/common/KnowledgeCategory';
import { ResizableBox } from 'react-resizable';
import 'react-resizable/css/styles.css';
function KnowledgeArticle() {
    const navigate = useNavigate();
    const initialId = localStorage.getItem('knowledgeBackId'); // Use initial state from location
    const [id, setId] = useState(initialId || "");
    const [refetches,Setrefetcher] = useState(id);
    const [isVisible, setIsVisible] = useState(false);
    const toggleVisibility = () => {
        setIsVisible(!isVisible);
      };
    const closeElement = () => {
        setIsVisible(false);
    };
    const [articles, setArticles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [paginatedata, setPaginateData] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState("");
    const [reroute, setReroute] = useState();
    const [subcatSend, setSubcatsSend] = useState(refetches);

    const catId = localStorage.getItem('knowledgeCatBackId'); // Use initial state from location
    const [catid, setCatId] = useState(catId || "");
    const [refetchescatid,Setrefetchercatid] = useState(catid);
    const [catSend, setSelectedCategory] = useState(refetchescatid);

    const kbaseId = localStorage.getItem('knowledgeKbaseBackId'); // Use initial state from location
    const [kbaseid, setKbaseId] = useState(kbaseId || "");
    const [refetcheskbaseid,SetrefetcherKbaseid] = useState(kbaseid);
    const [kbaseidSend, setSelectedKbaseId] = useState(refetcheskbaseid);
    

    const handleKbaseSelect = (kBaseId) => {
        setSelectedKbaseId(kBaseId);
        setSelectedCategory("");
        setSubcatsSend("");
    };
    const handleCategorySelect = (categoryId) => {
        setSelectedCategory(categoryId);
        setSubcatsSend("");
        setSelectedKbaseId("");
    };
    const handleSubCategorySelect = (subCategoryId) => {
        setSubcatsSend(subCategoryId);
        setSelectedCategory("");
        setSelectedKbaseId("");
    };

    const fetchArticles = useCallback(async (params = {}) => {
        setLoading(true);
        try {
            const defaultParams = {base_id: kbaseidSend,category_id: catSend, subcategory_id: subcatSend ,...params };
            const queryString = new URLSearchParams(defaultParams).toString();
            const response = await apiService.getRequest(`/knowledge-list-articles?${queryString}`);
            if (response && response.success === true) {
                setArticles(response.data);
                setReroute(response)
                setPaginateData(response.meta);
                setId('');
                setCatId('');
                setKbaseId('');
                Setrefetcher("");
                Setrefetchercatid("");
                SetrefetcherKbaseid("");
            }
        } catch (error) {
            console.error('Error fetching tab data:', error);
        } finally {
            localStorage.removeItem('knowledgeBackId');
            localStorage.removeItem('knowledgeCatBackId');
            localStorage.removeItem('knowledgeKbaseBackId');
            setLoading(false);
        }
    }, [kbaseidSend,catSend,subcatSend]);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
        fetchArticles({ page: newPage });
    };

    const handleSearch = () => {
        setCurrentPage(1);
        fetchArticles({ search: searchQuery });
    };

    useEffect(() => {
        fetchArticles();
    }, [fetchArticles]);
    const truncateHTML = (htmlContent, limit) => {
        const tempElement = document.createElement("div");
        tempElement.innerHTML = htmlContent;
        const plainText = tempElement.textContent || tempElement.innerText || "";
        const truncatedText = plainText.length > limit ? plainText.slice(0, limit) + "..." : plainText;
        return `<p>${truncatedText}</p>`;
    };
  return (
    <div className='container-fluid px-0'>
        <div className="resizable-container d-flex">
            <div className="d-md-block d-none">
                <ResizableBox
                    className="knowledge-category"
                    width={400}
                    height={Infinity}
                    minConstraints={[400, Infinity]}
                    maxConstraints={[window.innerWidth - 200, Infinity]}
                    axis="x"
                    resizeHandles={['e']}
                    >
                    <div className="knowledge-category-content">
                        <KnowledgeCategory onClose={closeElement} onCategorySelect={handleCategorySelect} onSubCategorySelect={handleSubCategorySelect} onKbaseSelect={handleKbaseSelect}/>
                    </div>
                </ResizableBox>
            </div>
            <div className="d-md-none d-block">
                <div className={isVisible !== true ? 'hide_mobitr' : 'show_mobitr'}>
                    <KnowledgeCategory onClose={closeElement} onCategorySelect={handleCategorySelect} onSubCategorySelect={handleSubCategorySelect} onKbaseSelect={handleKbaseSelect} />
                </div>
            </div>
            <div className='content_container_articals_drag' style={{ flexGrow: 1 }}>
                <Grid size={{sm:12}} container className="main_jobseeker" style={{background:"#fff"}}>
                    <Grid size={{sm:12}} container alignItems="center">
                        <Grid size={{xs:12}} container flexDirection="column" className="jobseekerhead">
                            <h3>Knowledge Article</h3>
                            <span><b onClick={()=>navigate("/dashboard")}>Dashboard</b> <FontAwesomeIcon className='angleright' icon={faAngleRight} /> <b onClick={()=>{setSelectedKbaseId("");setSelectedCategory("");setSubcatsSend("")}}>Knowledge Article </b>
                            {reroute && reroute.base_id ? <><FontAwesomeIcon className='angleright' icon={faAngleRight} /><b onClick={()=>{setSelectedKbaseId(reroute.base_id);setSelectedCategory("");setSubcatsSend("")}}>{reroute.base_name}</b></>: ""} 
                            {reroute && reroute.category_id ? <><FontAwesomeIcon className='angleright' icon={faAngleRight} /><b onClick={()=>{setSelectedCategory(reroute.category_id);setSubcatsSend("");setSelectedKbaseId("")}}>{reroute.category_name}</b></>: ""} 
                            {reroute && reroute.subcategory_id ? <><FontAwesomeIcon className='angleright' icon={faAngleRight} /><b onClick={()=>{setSubcatsSend(reroute.subcategory_id);setSelectedKbaseId("");setSelectedCategory("")}}>{reroute.subcategory_name}</b></>: ""} 
                            </span>
                        </Grid>
                        <button onClick={toggleVisibility} className='d-md-none main_category_btn'>
                            Category
                        </button>
                        <Grid size={{md:12,xs:12}} container justifyContent="flex-end" alignItems="center" className="my-4">
                            <Grid size={{md:4,xs:12}} container flexDirection="column">
                                <div className='d-flex jobseekersearch my-3'>
                                    <div className='col-2'>
                                        <FontAwesomeIcon className='iconcol' icon={faMagnifyingGlass} />
                                    </div>
                                    <div className='col-7'>
                                        <input type='text' placeholder='search'
                                            value={searchQuery}
                                            onChange={(e) => setSearchQuery(e.target.value)}/>
                                    </div>
                                    <div className='col-3'>
                                        <button onClick={handleSearch}>Search</button>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid size={{sm:12}} container justifyContent="center" className="mb-4">
                    {loading ? 
                        <div className='d-flex justify-content-center w-100'>
                            <img style={{ width: "100px" }} src={require('../../assets/pinkloader.gif')} alt='Loading...' />
                        </div> :<>
                        {articles && articles.length > 0 ? (
                            articles && articles.map((article) => (
                            <Grid key={article.id} size={{sm:12}} container justifyContent="center" className="knowledge_lister my-4">
                                <span style={{cursor:"pointer"}} className='questionskn' onClick={() => navigate(`/knowledge-article/${article.format_id}`)}><b className="articleformat_id">{article.format_id}</b> {article.title}</span>
                                <div className='w-100' dangerouslySetInnerHTML={{ __html: truncateHTML(article.content, 380) }} />
                                <Grid size={{xs:12}} container className="span_griders">
                                    <span><FontAwesomeIcon icon={faUser} className='icon_kn'/><small>Authored by :</small>{article.author.name}</span>
                                    <span><FontAwesomeIcon icon={faEye} className='icon_kn'/>{article.view_count}</span>
                                    <span><FontAwesomeIcon icon={faHandshake} className='icon_kn'/>{article.helpful_true_count} Helpful</span>
                                <span><FontAwesomeIcon icon={faHandshakeSimpleSlash} className='icon_kn'/>{article.helpful_false_count} UnHelpful</span>
                                    <span><FontAwesomeIcon icon={faCalendar} className='icon_kn'/>{article.created_at}</span>
                                    <Rating className='rating_spanhiders' disabled value={JSON.parse(article.overall_rating)} />
                                </Grid>
                            </Grid>
                            ))
                        ) : (
                            <div className="w-100 d-flex justify-content-center mt-4">
                                <p>No data to display</p>
                            </div>
                        )}
                    </>}
                    </Grid>
                    <Grid size={{xs:12}} container justifyContent="center" className="mt-4 pagination_btn">
                        <button className='arrowpaginate'
                            onClick={() => handlePageChange(currentPage - 1)} 
                            disabled={currentPage === 1}>
                            <FontAwesomeIcon icon={faChevronLeft} />
                        </button>
                        {Array.from({ length: paginatedata && paginatedata.last_page }, (_, index) => (
                            <button 
                                key={index} 
                                onClick={() => handlePageChange(index + 1)}
                                className={currentPage === index + 1 ? 'active' : ''}
                            >
                                {index + 1}
                            </button>
                        ))}
                        <button className='arrowpaginate'
                            onClick={() => handlePageChange(currentPage + 1)} 
                            disabled={currentPage === paginatedata.last_page}>
                            <FontAwesomeIcon icon={faChevronRight} />
                        </button>
                    </Grid>
                </Grid>
            </div>
        </div>
    </div>
  )
}

export default KnowledgeArticle
