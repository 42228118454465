import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid2';
import "./admin.css";
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdminRoletabs from '../../components/common/AdminRoletabs';
import { apiService } from '../../services/apiService';
// import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import DataTables from '../../components/dataTables/DataTables';
function UserJobSeekers() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [tablelist, setTableList] = useState([]);

    useEffect(() => {
        const fetchTablejobseeker = async () => {
        setLoading(true);
          try {
            const response = await apiService.postRequest('/admin/jobseekers');
            if(response.success === true){
                setTableList(response.data);
            }
          } catch (error) {
            console.error('Error fetching jobseeker status list:', error);
          }finally{
            setLoading(false);
          }
        };
        fetchTablejobseeker();
      }, []);
    
    

    // const handleSuspend = async (jobseekerId, isSuspended) => {
    //     setsusLoad(true);
    //     try {
    //         await apiService.postRequest(`/admin/jobseeker/${jobseekerId}/suspend`);

    //         if (isSuspended) {
    //             toast('Jobseeker Unsuspended successfully!');
    //         } else {
    //             toast('Jobseeker Suspended successfully!');
    //         }
    //         fetchJobseeker();
    //     } catch (err) {
    //         toast.error(`Failed to ${isSuspended ? 'unsuspend' : 'suspend'} jobseeker`);
    //     } finally{
    //         setsusLoad(false);
    //     }
    // };

    // Fetch initial data on component mount
    // useEffect(() => {
    //     fetchJobseeker();
    // }, [fetchJobseeker]);

    
  return (
    <div className='container-fluid px-0'>
        <div className='content_container'>
            <Grid size={{lg:12}} container className="main_jobseeker">
                <Grid size={{lg:12}} container alignItems="center" justifyContent="space-between">
                    <Grid size={{md:8,xs:12}} container flexDirection="column" className="jobseekerhead">
                        <h3>User</h3>
                        <span><b onClick={()=>navigate("/dashboard")}>Dashboard</b> <FontAwesomeIcon className='angleright' icon={faAngleRight} /> User <FontAwesomeIcon className='angleright' icon={faAngleRight} /> Jobseekers</span>
                    </Grid>
                    <Grid size={{xs:12}} container justifyContent="space-between" className="statusselectbtn my-4">
                        <Grid size={{md:6,sm:8,xs:12}} container>
                            <AdminRoletabs/>
                        </Grid>
                    </Grid>
                </Grid>
                {loading ? 
                    <div className='d-flex justify-content-center w-100'>
                        <img style={{ width: "100px" }} src={require('../../assets/pinkloader.gif')} alt='Loading...' />
                    </div> :<>
                    <DataTables jobseekers={tablelist} 
                    // onSuspend={handleSuspend}
                    />
                </>}
            </Grid>
        </div>
    </div>
  )
}

export default UserJobSeekers
