import React, { useEffect } from 'react';
import "./dashboard.css"
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { apiService } from '../../services/apiService';

const firebaseConfig = {
  apiKey: "AIzaSyD02rMLZSXId5gXEgowqt-N8f_gPyCYuxU",
  authDomain: "bench-track.firebaseapp.com",
  projectId: "bench-track",
  storageBucket: "bench-track.appspot.com",
  messagingSenderId: "18153078782",
  appId: "1:18153078782:web:1c312dbf6947ccae200aaf",
  measurementId: "G-VG4G8QERNK"
};
function Dashboard() {
  // const [token, setToken] = useState('');

  useEffect(() => {
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const messaging = getMessaging(app);

    // Function to request permission and get token
    const requestPermission = async () => {
      try {
        const permission = await Notification.requestPermission();
        if (permission === 'granted') {
          console.log('Notification permission granted.');

          // Get the FCM token
          const currentToken = await getToken(messaging, { vapidKey: 'BJ4G6kSXuE2mnML3InkqKeCINeNXQyukVAptiyMLH3ZHvfe91ebLgbrX-9uVGJPyVqB1PUks9qxelSRA9PleUys' });
          if (currentToken) {
            console.log('FCM Token:', currentToken);
            // setToken(currentToken);
            sendTokenToServer(currentToken);
            // You can send the token to your server here
          } else {
            console.log('No registration token available. Request permission to generate one.');
          }
        } else {
          console.log('Unable to get permission to notify.');
        }
      } catch (error) {
        console.error('Error getting permission or token', error);
      }
    };

    // Call the request permission function
    requestPermission();

    // Handle incoming messages
    onMessage(messaging, (payload) => {
      console.log('Message received. ', payload);
      // Show notification in the UI (optional)
      const notificationTitle = payload.notification.title;
      const notificationOptions = {
        body: payload.notification.body,
        icon: payload.notification.icon,
      };
      new Notification(notificationTitle, notificationOptions);
    });
  }, []);
  useEffect(() => {
    // Check if the page has already been reloaded
    const hasReloaded = localStorage.getItem('hasReloaded');
    
    if (!hasReloaded) {
      // Set a flag in localStorage to avoid infinite reloads
      localStorage.setItem('hasReloaded', 'true');
      // Reload the page
      window.location.reload();
    }
  }, []);

  const sendTokenToServer = async (token) => {
    try {
      const response = await apiService.postRequest('/save-device-token', { device_token:token });
      console.log('Token saved successfully:', response.data);
    } catch (error) {
      console.error('Error saving token:', error);
    }
  };
  return (
    <div className='container-fluid px-0'>
        <div className='content_container'>
            <h1>Dashboard</h1>
            {/* {token ? <p>Device Token: {token}</p> : <p>No device token available.</p>} */}
        </div>
    </div>
  )
}

export default Dashboard
