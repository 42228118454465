import React, { useState } from 'react'
import Grid from '@mui/material/Grid2';
import "./terms.css";
import { useNavigate } from 'react-router-dom';
function TermsandCondition() {
    const navigate = useNavigate();
    const [termsAgreed, setTermsAgreed] = useState(false);
    const [error, setError] = useState(''); // State to hold error message
  
    // Handle checkbox change
    const handleCheckboxChange = (e) => {
      setTermsAgreed(e.target.checked);
      setError(''); // Clear error if checkbox is checked
    };
  
    // Handle form submission
    const handleSubmit = (e) => {
      e.preventDefault();
      if (termsAgreed) {
        navigate("/registration");
      } else {
        setError('You must agree to the terms and conditions to proceed.');
      }
    };
  return (
    <Grid size={{lg:12}} container justifyContent="center" className="termsconditionmain">
        <Grid size={{md:10,xs:11}} container justifyContent="flex-start" alignItems="flex-start" flexDirection="column">
            <img onClick={() =>{navigate("/")}} src={require('../../assets/logo.svg').default} alt=''/>
            <h2>Terms and Conditions</h2>
            <h5>Welcome to Bench Track!</h5>
            <small>These terms and conditions outline the rules and regulations for the use of Company Name's Website, located at Website.com.</small>
            <small>By accessing this website we assume you accept these terms and conditions. Do not continue to use Website Name if you do not agree to take all of the terms and conditions stated on this page.</small>
            <small>The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and all Agreements: “Client”, “You” and “Your” refers to you, the person log on this website and compliant to the Company's terms and conditions. “The Company”, “Ourselves”, “We”, “Our” and “Us”, refers to our Company. “Party”, “Parties”, or “Us”, refers to both the Client and ourselves. All terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner for the express purpose of meeting the Client's needs in respect of provision of the Company's stated services, in accordance with and subject to, prevailing law of Netherlands. Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to same.</small>
            <h5>Cookies</h5>
            <small>We employ the use of cookies. By accessing Website Name, you agreed to use cookies in agreement with the Company Name's Privacy Policy.<br/>Most interactive websites use cookies to let us retrieve the user's details for each visit. Cookies are used by our website to enable the functionality of certain areas to make it easier for people visiting our website. Some of our affiliate/advertising partners may also use cookies.</small>
            <h5>License</h5>
            <small>Unless otherwise stated, Company Name and/or its licensors own the intellectual property rights for all material on Website Name. All intellectual property rights are reserved. You may access this from Website Name for your own personal use subjected to restrictions set in these terms and conditions.</small>
        </Grid>
        <Grid size={{md:10,xs:11}} container justifyContent="center">
        <form onSubmit={handleSubmit} className='d-flex w-100 flex-column align-items-center'>
            <span>
              <input 
                type="checkbox" 
                id="terms" 
                onChange={handleCheckboxChange}
              />
              <label htmlFor="terms">I agree to the Terms & Conditions <span>*</span></label>
            </span>
            {error && <p style={{ color: 'red', marginTop: '10px',width:"100%" }}>{error}</p>} {/* Display error message */}
            <button type="submit" className={!termsAgreed ? "disabled_btns" : ""}>
              Submit
            </button>
          </form>
        </Grid>
    </Grid>
  )
}

export default TermsandCondition
