import React from 'react'
import Grid from '@mui/material/Grid2';
import "./catalogs.css";
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
function MarketHold() {
    const navigate = useNavigate();
  return (
    <div className='container-fluid px-0'>
        <div className='content_container'>
            <Grid size={{sm:12}} container className="main_jobseeker">
                <Grid size={{sm:12}} container alignItems="center">
                    <Grid size={{md:8,xs:12}} container flexDirection="column" className="jobseekerhead">
                        <h3>Marketing Hold request</h3>
                        <span><b onClick={()=>navigate("/dashboard")}>Dashboard</b> <FontAwesomeIcon className='angleright' icon={faAngleRight} /> <b>Catalog</b><FontAwesomeIcon className='angleright' icon={faAngleRight} /> Marketing Hold request</span>
                    </Grid>
                </Grid>
                <Grid size={{md:12,xs:12}} container justifyContent="flex-end" alignItems="center" className="my-4 request_user_edit">
                    <Grid size={{xs:12}} container justifyContent="space-between" className="mt-4">
                        <Grid size={{md:4,sm:3,xs:12}} container className="mt-3">
                            <img src={require('../../../assets/Requestedits/markethold.png')} alt=''/>
                        </Grid>
                        <Grid size={{md:7,sm:8,xs:12}} container flexDirection="column" justifyContent="flex-start" className="mt-3 requestedit_quat px-3">
                            <h3>Marketing Hold request</h3>
                            <span>Use this form to submit request for Correct My Profile</span>
                            <span>Use this form to request changes to your profile, such as updating your contact information, adjusting your preferences, or correcting any errors.</span>
                        </Grid>
                        <Grid size={{xs:12}} container>
                            <form className='w-100' 
                            // onSubmit={handleSubmit}
                            >
                                <Grid size={{lg:12,xs:12}} container justifyContent="space-between" className="regiter_inputs">
                                    <Grid size={{sm:12}} container className="my-3" flexDirection="column">
                                        <span>Do you believe that the Candidate is a True and Expereinced ServiceNow Resource ?<span style={{color:"red"}}> *</span></span>
                                        <div className='d-flex align-items-center main_radioregister'>
                                            <input
                                                type="radio" 
                                                name="laws_and_regulations" 
                                                // checked={formData.laws_and_regulations} 
                                                // onChange={handleInputChange} 
                                            />Yes
                                            <input
                                                type="radio" 
                                                name="laws_and_regulations" 
                                                // checked={formData.laws_and_regulations} 
                                                // onChange={handleInputChange} 
                                            />No
                                        </div>
                                    </Grid>
                                    <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                        <span>Name<span style={{color:"red"}}> *</span></span>
                                        <input
                                            type="text"
                                            name="first_name"
                                            onInput={(e) => e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, '')}
                                        />
                                    </Grid>
                                    <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                        <span>Last Name<span style={{color:"red"}}> *</span></span>
                                        <input
                                            type="text"
                                            name="last_name"
                                            onInput={(e) => e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, '')}
                                        />
                                    </Grid>
                                    <Grid size={{ md:5.5,sm:5.8, xs:12 }} container flexDirection="column">
                                        <span>Your Marketing email<span style={{color:"red"}}> *</span></span>
                                        <input
                                            type="email"
                                            name="email"
                                            // value={formData.phone_number}
                                            // onChange={handlePhoneNumberChange}
                                            placeholder="example@gmail.com"
                                        />
                                    </Grid>
                                    <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                        <span>Phone Number<span style={{color:"red"}}> *</span></span>
                                        <input
                                            type="text"
                                            name="phone_number"
                                            // value={formData.phone_number}
                                            // onChange={handlePhoneNumberChange}
                                            placeholder="***_***_****"
                                            maxLength="14" // To ensure no extra characters
                                            // className={errors.phone_number ? 'input-error' : ''}
                                        />
                                    </Grid>
                                    <Grid size={{sm:12}} container className="my-3" flexDirection="column">
                                        <span>Type of Request<span style={{color:"red"}}> *</span></span>
                                        <div className='d-flex align-items-center main_radioregister'>
                                            <input
                                                type="radio" 
                                                name="laws_and_regulations" 
                                                // checked={formData.laws_and_regulations} 
                                                // onChange={handleInputChange} 
                                            />Yes
                                            <input
                                                type="radio" 
                                                name="laws_and_regulations" 
                                                // checked={formData.laws_and_regulations} 
                                                // onChange={handleInputChange} 
                                            />No
                                        </div>
                                    </Grid>
                                    <Grid size={{sm:12, xs:12}} container flexDirection="column">
                                        <span>Reason for Changes<span style={{color:"Red"}}> *</span></span>
                                        <textarea placeholder='Describe' rows="10" name="reson"
                                            // value={formData.renew_authorization_work}
                                            // onChange={handleInputChange}
                                            />
                                    </Grid>
                                    <Grid size={{sm:12, xs:12}} container justifyContent="space-between" className="mt-2">
                                        <button className='registersendbtn2'>Submit</button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    </div>
  )
}

export default MarketHold
