import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./reviewcarosel.css"
function ReviewCarosel() {
    const sliderRef = useRef(null);
  const settings = {
    className: "center",
    centerMode: false,
    infinite: true,
    centerPadding: "200px",
    slidesToShow: 2,
    speed: 500,
    responsive: [
        {
          breakpoint: 600,
          settings: {
            centerMode: false,
            slidesToShow: 1,
          }
        },
      ]
  };
  
  const nextSlide = () => {
    sliderRef.current.slickNext();
  };

  const prevSlide = () => {
    sliderRef.current.slickPrev();
  };
  return (
    <>
    <div className="slider-container">
      <Slider ref={sliderRef} {...settings}>
        <div>
            <div className="px-3">
                <div className="d-flex flex-column main_carocontens">
                    <img src={require('../../assets/comment.svg').default} alt=''/>
                    <span>As a recruiter, BenchTrack has been a game-changer. The app's analytics and candidate tracking features are invaluable</span>
                    <h4>- John D., <small>HR Manager</small></h4>
                </div>
            </div>
        </div>
        <div>
            <div className="px-3">
                <div className="d-flex flex-column main_carocontens">
                    <img src={require('../../assets/comment.svg').default} alt=''/>
                    <span>Bench Track made my job search so much easier. I found my current position within a week of signing up!</span>
                    <h4>- Sarah M., <small>Marketing Specialist</small></h4>
                </div>
            </div>
        </div>
        
      </Slider>
    </div>
    <div className="button-container">
        <button onClick={prevSlide} className="prev-button">
            <img src={require('../../assets/leftarrow.svg').default} alt=''/>
        </button>
        <button onClick={nextSlide} className="next-button">
            <img src={require('../../assets/rightarrow.svg').default} alt=''/>
        </button>
      </div>
    </>
  );
}

export default ReviewCarosel;
