import React, { useState } from 'react';
import Grid from '@mui/material/Grid2';
import "./homepage.css";
import ReviewCarosel from '../../components/carosel/ReviewCarosel';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';

function Homepage() {
    const [isJobSeekersActive, setIsJobSeekersActive] = useState(true);

    const handleJobSeekersClick = () => {
        setIsJobSeekersActive(true);
    };

    const handleEmployersClick = () => {
        setIsJobSeekersActive(false);
    };
  return (
    <>
    <Header/>
    <Grid container size={{lg:12}} justifyContent="center">
        <Grid container size={{lg:12}} justifyContent="center" className="hero_section mb-5" alignContent="center">
            <Grid container size={{lg:12,md:12,sm:10,xs:11}} justifyContent="center">
                <h1 className='d-lg-block d-none'>Take Control of your Job Search<br/>and Hiring Today</h1>
                <h1 className='d-lg-none'>Take Control of your<br/> Job Search and <br/>Hiring Today</h1>
            </Grid>
            <Grid container size={{lg:8,md:8,sm:11,xs:11}} justifyContent="center" alignItems="center" flexDirection="column">
                <h3>Simplify Your Job Search and Hiring Process with BenchTrack</h3>
                <span>Streamline your recruitment efforts and discover top talent effortlessly with our cutting-edge job consultancy app.</span>
                <button>Enquire Now</button>
            </Grid>
            <img className='hero_float1' src={require('../../assets/hero_float1.png')} alt=''/>
            <img className='hero_float2' src={require('../../assets/hero_float2.png')} alt=''/>
            <img className='hero_float3 d-lg-block d-none' src={require('../../assets/hero_float3.png')} alt=''/>
            <img className='hero_float4 d-lg-block d-none' src={require('../../assets/hero_float4.png')} alt=''/>
            <img className='hero_float5' src={require('../../assets/hero_float5.png')} alt=''/>
            <img className='hero_float6' src={require('../../assets/hero_float6.png')} alt=''/>
        </Grid>
        <Grid container size={{lg:12}} justifyContent="center" className="why_choosebench">
            <Grid size={{xs:11}} container justifyContent="center">
                <h1>Why Choose BenchTrack?</h1>
            </Grid>
            <Grid size={{lg:8,md:10,xs:11}} container justifyContent="space-evenly" alignItems="center" alignContent="center" className="flex-md-row-reverse">
                <Grid size={{sm:4,xs:12}} container alignItems="center" className="imghover_hiders">
                    <img src={require('../../assets/second_hero.png')} alt=''/>
                </Grid>
                <Grid size={{sm:7,xs:12}} container justifyContent="center" alignContent="center">
                    <Grid size={{xs:12}} container className="whitewhy_body" flexDirection="column">
                        <h5>Efficient Job Matching</h5>
                        <small>Our advanced algorithms match job seekers with the most suitable opportunities, ensuring both candidates and employers find their perfect fit.</small>
                    </Grid>
                    <Grid size={{xs:12}} container className="whitewhy_body" flexDirection="column">
                        <h5>Real-Time Updates</h5>
                        <small>Stay ahead with real-time notifications about new job listings, candidate applications, and interview schedules, all within the app.</small>
                    </Grid>
                    <Grid size={{xs:12}} container className="whitewhy_body" flexDirection="column">
                        <h5>Comprehensive Analytics</h5>
                        <small>Track your hiring process with detailed analytics and reports, making informed decisions easier than ever.</small>
                    </Grid>
                    <Grid size={{xs:12}} container className="whitewhy_body" flexDirection="column">
                        <h5>User-Friendly Interface</h5>
                        <small>Designed with simplicity in mind, Bench Track offers a seamless experience for both job seekers and employers.</small>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <Grid container size={{lg:12}} justifyContent="center" className="key_featuremain">
            <Grid size={{xs:11}} container justifyContent="center" className="mt-4">
                <h1>Key Features</h1>
            </Grid>
            <Grid size={{xs:12}} container justifyContent="center">
                <div className='button_keyfeatures'>
                    <button className={isJobSeekersActive ? 'activekeyfeture' : ''} onClick={handleJobSeekersClick}>Job Seekers</button>
                    <button className={!isJobSeekersActive ? 'activekeyfeture' : ''} onClick={handleEmployersClick}>Employers</button>
                </div>
            </Grid>
            {isJobSeekersActive ? 
            <Grid size={{ lg:8,md:10,xs:11}} container justifyContent="space-between" className="mt-4 mb-5">
                <Grid size={{sm:3.8}} container justifyContent="flex-start" flexDirection="column" className="card_keyfeature my-2">
                    <img src={require('../../assets/keyfeatureicon/icon1.svg').default} alt=''/>
                    <h5>Smart Job Search</h5>
                    <small>Filter and find job openings that match your skills and preferences.</small>
                </Grid>
                <Grid size={{sm:3.8}} container justifyContent="flex-start" flexDirection="column" className="card_keyfeature my-2">
                    <img src={require('../../assets/keyfeatureicon/icon2.svg').default} alt=''/>
                    <h5>Instant Notifications</h5>
                    <small>Receive alerts for new job postings, application status, and interview schedules.</small>
                </Grid>
                <Grid size={{sm:3.8}} container justifyContent="flex-start" flexDirection="column" className="card_keyfeature my-2">
                    <img src={require('../../assets/keyfeatureicon/icon3.svg').default} alt=''/>
                    <h5>Profile Management</h5>
                    <small>Create and update your profile effortlessly, showcasing your experience and skills.</small>
                </Grid>
            </Grid>
            :
            <Grid size={{lg:8,md:10,xs:11}} container justifyContent="space-between" className="mt-4 mb-5">
                <Grid size={{sm:3.8}} container justifyContent="flex-start" flexDirection="column" className="card_keyfeature my-2">
                    <img src={require('../../assets/keyfeatureicon/icon4.svg').default} alt=''/>
                    <h5>Candidate Tracking</h5>
                    <small>Manage your candidate pipeline with ease, from application to hiring.</small>
                </Grid>
                <Grid size={{sm:3.8}} container justifyContent="flex-start" flexDirection="column" className="card_keyfeature my-2">
                    <img src={require('../../assets/keyfeatureicon/icon5.svg').default} alt=''/>
                    <h5>Customizable Job Listings</h5>
                    <small>Manage your candidate pipeline with ease, from application to hiring.</small>
                </Grid>
                <Grid size={{sm:3.8}} container justifyContent="flex-start" flexDirection="column" className="card_keyfeature my-2">
                    <img src={require('../../assets/keyfeatureicon/icon6.svg').default} alt=''/>
                    <h5>Interview Scheduling</h5>
                    <small>Coordinate interview times and communicate directly with candidates.</small>
                </Grid>
            </Grid>
            }
        </Grid>
        <Grid container size={{lg:12}} justifyContent="center" className="trackworkmain">
            <Grid size={{xs:11}} container justifyContent="center">
                <h1>How Bench Track Works</h1>
            </Grid>
            <Grid container className="w-100 d-md-block d-none">
                <Grid container size={{xs:12}} justifyContent="center">
                    <Grid size={{xs:11}} container justifyContent="center">
                        <Grid size={{lg:3,md:5,sm:5.5}} container className="maintrackminset" alignItems="flex-start">
                            <Grid size={{xs:12}} container className="mintrack_height" alignContent="space-between">
                                <Grid size={{xs:12}} container justifyContent="flex-start" className="content_trackprocess">
                                    <Grid size={{sm:2}} container justifyContent="flex-end" alignItems="flex-start">
                                        <img className='mt-2' src={require('../../assets/trackicons/icon1.svg').default} alt=''/>
                                    </Grid>
                                    <Grid size={{sm:7}} container className="arow_traccontent">
                                        <h5>Sign Up</h5>
                                        <small>Register quickly as a job seeker or employer to get started.</small>
                                    </Grid>
                                    <Grid size={{sm:3}} className="mb-2" container justifyContent="flex-start" alignItems="flex-end">
                                        <img src={require('../../assets/trackicons/arrow1.svg').default} alt=''/>
                                    </Grid>
                                </Grid>
                                <Grid size={{xs:12}} container justifyContent="flex-start" className="content_trackprocess">
                                    <Grid size={{sm:2}} container justifyContent="flex-end" alignItems="flex-start">
                                        <img className='mt-2' src={require('../../assets/trackicons/icon3.svg').default} alt=''/>
                                    </Grid>
                                    <Grid size={{sm:7}} container className="arow_traccontent">
                                        <h5>Connect</h5>
                                        <small>Job seekers apply directly through the app, while employers review and manage candidates.</small>
                                    </Grid>
                                    <Grid size={{sm:3}} className="mb-2" container justifyContent="flex-start" alignItems="flex-end">
                                        <img src={require('../../assets/trackicons/arrow3.svg').default} alt=''/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container className="dash_linetrack"/>
                        <Grid size={{lg:3,md:5,sm:5.5}} container className="maintrackminset" alignItems="flex-end">
                            <Grid size={{xs:12}} container className="mintrack_height" alignContent="space-between">
                                <Grid size={{xs:12}} container justifyContent="flex-start" className="content_trackprocess">
                                    <Grid size={{sm:3}} className="mb-2" container justifyContent="flex-end" alignItems="flex-end">
                                        <img src={require('../../assets/trackicons/arrow2.svg').default} alt=''/>
                                    </Grid>
                                    <Grid size={{sm:7}} container className="arow_traccontent">
                                        <h5>Explore</h5>
                                        <small>Browse through job listings or search for top talent based on your requirements.</small>
                                    </Grid>
                                    <Grid size={{sm:2}} container justifyContent="flex-start" alignItems="flex-start">
                                        <img className='mt-2' src={require('../../assets/trackicons/icon2.svg').default} alt=''/>
                                    </Grid>
                                </Grid>
                                <Grid size={{xs:12}} container justifyContent="flex-start" className="content_trackprocess">
                                    <Grid size={{sm:3}} className="mb-2" container justifyContent="flex-end" alignItems="flex-end">
                                        <img src={require('../../assets/trackicons/arrow4.svg').default} alt=''/>
                                    </Grid>
                                    <Grid size={{sm:7}} container className="arow_traccontent">
                                        <h5>Hire or Get Hired</h5>
                                        <small>Streamline your hiring process or land your dream job with Bench Track.</small>
                                    </Grid>
                                    <Grid size={{sm:2}} container justifyContent="flex-start" alignItems="flex-start">
                                        <img className='mt-2' src={require('../../assets/trackicons/icon4.svg').default} alt=''/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container className="w-100 d-md-none">
                <Grid container size={{xs:12}} justifyContent="center">
                    <Grid size={{xs:11}} container justifyContent="space-evenly" className="">
                        <Grid size={{xs:12}} container justifyContent="flex-start" className="content_trackprocess">
                            <Grid size={{xs:1.5}} container justifyContent="flex-end" alignItems="flex-start">
                                <img className='mt-2' src={require('../../assets/trackicons/icon1.svg').default} alt=''/>
                            </Grid>
                            <Grid size={{xs:8}} container className="arow_traccontent">
                                <h5>Sign Up</h5>
                                <small>Register quickly as a job seeker or employer to get started.</small>
                            </Grid>
                            <Grid size={{xs:2.5}} className="mb-2" container justifyContent="flex-start" alignItems="flex-end">
                                <img src={require('../../assets/trackicons/arrow1.svg').default} alt=''/>
                            </Grid>
                        </Grid>
                        <Grid size={{xs:12}} container justifyContent="flex-start" className="content_trackprocess">
                            <Grid size={{xs:1.5}} container justifyContent="flex-end" alignItems="flex-start">
                                <img className='mt-2' src={require('../../assets/trackicons/iconmobi2.svg').default} alt=''/>
                            </Grid>
                            <Grid size={{xs:8}} container className="arow_traccontent">
                                <h5>Explore</h5>
                                <small>Browse through job listings or search for top talent based on your requirements.</small>
                            </Grid>
                            <Grid size={{xs:2.5}} className="mb-2" container justifyContent="flex-start" alignItems="flex-end">
                                <img src={require('../../assets/trackicons/arrowmobi2.svg').default} alt=''/>
                            </Grid>
                        </Grid>
                        <Grid size={{xs:12}} container justifyContent="flex-start" className="content_trackprocess">
                            <Grid size={{xs:1.5}} container justifyContent="flex-end" alignItems="flex-start">
                                <img className='mt-2' src={require('../../assets/trackicons/icon3.svg').default} alt=''/>
                            </Grid>
                            <Grid size={{xs:8}} container className="arow_traccontent">
                                <h5>Connect</h5>
                                <small>Job seekers apply directly through the app, while employers review and manage candidates.</small>
                            </Grid>
                            <Grid size={{xs:2.5}} className="mb-2" container justifyContent="flex-start" alignItems="flex-end">
                                <img src={require('../../assets/trackicons/arrow3.svg').default} alt=''/>
                            </Grid>
                        </Grid>
                        <Grid size={{xs:12}} container justifyContent="flex-start" className="content_trackprocess">
                            <Grid size={{xs:1.5}} container justifyContent="flex-end" alignItems="flex-start">
                                <img className='mt-2' src={require('../../assets/trackicons/iconmobi4.svg').default} alt=''/>
                            </Grid>
                            <Grid size={{xs:8}} container className="arow_traccontent">
                                <h5>Hire or Get Hired</h5>
                                <small>Streamline your hiring process or land your dream job with Bench Track.</small>
                            </Grid>
                            <Grid size={{xs:2.5}} className="mb-2" container justifyContent="flex-start" alignItems="flex-end">
                                <img src={require('../../assets/trackicons/arrowmobi4.svg').default} alt=''/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <Grid container size={{xs:12}} justifyContent="center" className="sucess_story">
            <Grid size={{xs:12}} container justifyContent="center">
                <h1>Success Stories</h1>
            </Grid>
            <Grid size={{md:8,xs:11}} container justifyContent="center">
                <ReviewCarosel/>
            </Grid>
        </Grid>
    </Grid>
    <Footer/>
    </>
  )
}

export default Homepage
