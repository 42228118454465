import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import AppRoutes from './routes/Routes';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';


const ScrollToTop = () => {
  const { pathname } = useLocation();
  // const navigate = useNavigate()
  
  useEffect(() => {
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual'; // Prevent auto-restoration of scroll
    }
    window.scrollTo(0, 0); // Set scroll position to the top immediately
  }, [pathname]);

  return null;
};

function App() {
  return (
    <>
    <ToastContainer />
    <Router>
      <ScrollToTop/>
        <AppRoutes/>
    </Router>
    </>
  );
}
export default App;
