import React from 'react'
import Grid from '@mui/material/Grid2';
import "./catalogs.css";
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
function Certifications() {
    const navigate = useNavigate();
  return (
    <div className='container-fluid px-0'>
        <div className='content_container'>
            <Grid size={{sm:12}} container className="main_jobseeker">
                <Grid size={{sm:12}} container alignItems="center">
                    <Grid size={{md:8,xs:12}} container flexDirection="column" className="jobseekerhead">
                        <h3>BenchTrack Certifications</h3>
                        <span><b onClick={()=>navigate("/dashboard")}>Dashboard</b> <FontAwesomeIcon className='angleright' icon={faAngleRight} /> <b>Catalog</b><FontAwesomeIcon className='angleright' icon={faAngleRight} /> BenchTrack Certifications</span>
                    </Grid>
                </Grid>
                <Grid size={{md:12,xs:12}} container justifyContent="flex-end" alignItems="center" className="my-4 request_user_edit">
                    <Grid size={{xs:12}} container justifyContent="space-between" className="mt-4">
                        <Grid size={{md:4,sm:3,xs:12}} container className="mt-3">
                            <img src={require('../../../assets/Requestedits/certification.png')} alt=''/>
                        </Grid>
                        <Grid size={{md:7,sm:8,xs:12}} container flexDirection="column" justifyContent="flex-start" className="mt-3 requestedit_quat px-3">
                            <h3>BenchTrack Certifications</h3>
                            <span>Use this form to submit BenchTrack Certifications</span>
                            <span>If you are trying to start or switch your career to ServiceNow, its important to get certified to boost your chances of landing in an interview. Beware of ServiceNow Certification Scams, Do not purchase 3rd party vouchers, Do not purchase/use Dumps.</span>
                        </Grid>
                        <Grid size={{xs:12}} container>
                            <form className='w-100' 
                            // onSubmit={handleSubmit}
                            >
                                <Grid size={{lg:12,xs:12}} container justifyContent="space-between" className="regiter_inputs">
                                    <Grid size={{md:5.5,sm:5.8, xs:12}} container className="my-3" flexDirection="column">
                                        <span>What type of Certification are you requesting<span style={{color:"red"}}> *</span></span>
                                        <Select
                                            className="basic-single select_withsearch"
                                            classNamePrefix="select"
                                            defaultValue={null}
                                            name="certification"
                                            // value={selectedState}
                                            // options={states.map((state) => ({
                                            //     label: state.name,
                                            //     value: state.state_code,
                                            // }))}
                                            // onChange={handleStateChange}
                                        />
                                    </Grid>
                                    <Grid size={{md:5.5,sm:5.8, xs:12}} container className="my-3" flexDirection="column">
                                        <span>Do you currently have a NowLearning Account ?<span style={{color:"red"}}> *</span></span>
                                        <div className='d-flex align-items-center main_radioregister'>
                                            <input
                                                type="radio" 
                                                name="laws_and_regulations" 
                                                // checked={formData.laws_and_regulations} 
                                                // onChange={handleInputChange} 
                                            />Yes
                                            <input
                                                className='ms-md-4'
                                                type="radio" 
                                                name="laws_and_regulations" 
                                                // checked={formData.laws_and_regulations} 
                                                // onChange={handleInputChange} 
                                            />No
                                            <input
                                                type="radio" 
                                                 className='ms-md-4'
                                                name="laws_and_regulations" 
                                                // checked={formData.laws_and_regulations} 
                                                // onChange={handleInputChange} 
                                            />I don’t know what is Now Learning
                                        </div>
                                    </Grid>
                                    <Grid size={{sm:12}} container className="my-3" flexDirection="column">
                                        <span>Choose any applicable option?<span style={{color:"red"}}> *</span></span>
                                        <div className='d-flex flex-column main_radioregister'>
                                            <div className='d-flex align-items-center'><input
                                                type="checkbox" 
                                                name="laws_and_regulations" 
                                                // checked={formData.laws_and_regulations} 
                                                // onChange={handleInputChange} 
                                            />I am yet to enroll for courses in NowLearning</div>
                                            <div className='d-flex align-items-center'><input
                                                type="checkbox" 
                                                name="laws_and_regulations" 
                                                // checked={formData.laws_and_regulations} 
                                                // onChange={handleInputChange} 
                                            />I have enrolled for courses in NowLearning, but i am yet to complete the course</div>
                                            <div className='d-flex align-items-center'><input
                                                type="checkbox" 
                                                name="laws_and_regulations" 
                                                // checked={formData.laws_and_regulations} 
                                                // onChange={handleInputChange} 
                                            />I have completed the course in NowLearning and I recevied a Voucher</div>
                                            <div className='d-flex align-items-center'><input
                                                type="checkbox" 
                                                name="laws_and_regulations" 
                                                // checked={formData.laws_and_regulations} 
                                                // onChange={handleInputChange} 
                                            />I don’t know how to answer this question</div>
                                        </div>
                                    </Grid>
                                    <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                        <span>Your Name<span style={{color:"red"}}> *</span></span>
                                        <input
                                            type="text"
                                            name="first_name"
                                            onInput={(e) => e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, '')}
                                        />
                                    </Grid>
                                    <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                        <span>Best number to contact you on whatsapp<span style={{color:"red"}}> *</span></span>
                                        <input
                                            type="text"
                                            name="phone_number"
                                            // value={formData.phone_number}
                                            // onChange={handlePhoneNumberChange}
                                            placeholder="***_***_****"
                                            maxLength="14" // To ensure no extra characters
                                            // className={errors.phone_number ? 'input-error' : ''}
                                        />
                                    </Grid>
                                    <Grid size={{ md:5.5,sm:5.8, xs:12 }} container flexDirection="column">
                                        <span>Best email address to reach you<span style={{color:"red"}}> *</span></span>
                                        <input
                                            type="email"
                                            name="email"
                                            // value={formData.phone_number}
                                            // onChange={handlePhoneNumberChange}
                                            placeholder="example@gmail.com"
                                        />
                                    </Grid>
                                    <Grid size={{sm:12, xs:12}} container justifyContent="space-between" className="mt-2">
                                        <button className='registersendbtn2'>Submit</button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    </div>
  )
}

export default Certifications
