import React, { useCallback, useEffect, useState } from 'react'
import Grid from '@mui/material/Grid2';
import { faChevronLeft, faChevronRight, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import "./knowledge.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { faCalendar, faUser } from '@fortawesome/free-regular-svg-icons';
import { apiService } from '../../services/apiService';
function UnderApproval() {
    const navigate = useNavigate();
    const [articles, setArticles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [paginatedata, setPaginateData] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState("");

    const fetchArticles = useCallback(async (params = {}) => {
        setLoading(true);
        try {
            const defaultParams = { status: 'under_approval', ...params };
            const queryString = new URLSearchParams(defaultParams).toString();
            const response = await apiService.getRequest(`/knowledge-article?${queryString}`);
            if (response && response.success === true) {
                setArticles(response.data);
                setPaginateData(response.meta);
            }
        } catch (error) {
            console.error('Error fetching tab data:', error);
        } finally {
            setLoading(false);
        }
    }, []);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
        fetchArticles({ page: newPage });
    };

    const handleSearch = () => {
        setCurrentPage(1);
        fetchArticles({ search: searchQuery });
    };

    useEffect(() => {
        fetchArticles();
    }, [fetchArticles]);
    const truncateHTML = (htmlContent, limit) => {
        const tempElement = document.createElement("div");
        tempElement.innerHTML = htmlContent;
        const plainText = tempElement.textContent || tempElement.innerText || "";
        const truncatedText = plainText.length > limit ? plainText.slice(0, limit) + "..." : plainText;
        return `<p>${truncatedText}</p>`;
      };
  return (
    <div className='container-fluid px-0'>
        <div className='content_container'>
            <Grid size={{sm:12}} container className="main_jobseeker">
                <Grid size={{sm:12}} container alignItems="center">
                    <Grid size={{md:8,xs:12}} container flexDirection="column" className="jobseekerhead">
                        <h3>Under Approval</h3>
                    </Grid>
                    <Grid size={{md:12,xs:12}} container justifyContent="space-between" alignItems="center" className="my-4">
                        <Grid size={{md:4,xs:12}} container flexDirection="column">
                            <div className='d-flex jobseekersearch my-3'>
                                <div className='col-2'>
                                    <FontAwesomeIcon className='iconcol' icon={faMagnifyingGlass} />
                                </div>
                                <div className='col-7'>
                                    <input type='text' placeholder='search'
                                        value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)}/>
                                </div>
                                <div className='col-3'>
                                    <button onClick={handleSearch}>Search</button>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid size={{sm:12}} container justifyContent="center" className="mb-4">
                {loading ? 
                    <div className='d-flex justify-content-center w-100'>
                        <img style={{ width: "100px" }} src={require('../../assets/pinkloader.gif')} alt='Loading...' />
                    </div> :<>
                    {articles && articles.length > 0 ? (
                        articles && articles.map((article) => (
                        <Grid key={article.id} size={{sm:12}} container justifyContent="center" className="knowledge_lister my-4">
                            <span style={{cursor:"pointer"}} className='questionskn' onClick={() => navigate(`/knowledge/under-approval/show/${article.id}`)}><b className="articleformat_id">{article.format_id}</b>{article.title}</span>
                            <div className='w-100' dangerouslySetInnerHTML={{ __html: truncateHTML(article.content, 380) }} />
                            <Grid size={{xs:12}} container className="span_griders">
                                <span><FontAwesomeIcon icon={faUser} className='icon_kn'/><small>Authored by :</small>{article.author.name}</span>
                                <span><FontAwesomeIcon icon={faCalendar} className='icon_kn'/>{article.created_at}</span>
                            </Grid>
                        </Grid>
                        ))
                    ) : (
                        <div className="w-100 d-flex justify-content-center mt-4">
                            <p>No data to display</p>
                        </div>
                    )}
                </>}
                </Grid>
                <Grid size={{xs:12}} container justifyContent="center" className="mt-4 pagination_btn">
                    <button className='arrowpaginate'
                        onClick={() => handlePageChange(currentPage - 1)} 
                        disabled={currentPage === 1}>
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </button>
                    {Array.from({ length: paginatedata && paginatedata.last_page }, (_, index) => (
                        <button 
                            key={index} 
                            onClick={() => handlePageChange(index + 1)}
                            className={currentPage === index + 1 ? 'active' : ''}
                        >
                            {index + 1}
                        </button>
                    ))}
                    <button className='arrowpaginate'
                        onClick={() => handlePageChange(currentPage + 1)} 
                        disabled={currentPage === paginatedata.last_page}>
                        <FontAwesomeIcon icon={faChevronRight} />
                    </button>
                </Grid>
            </Grid>
        </div>
    </div>
  )
}

export default UnderApproval
