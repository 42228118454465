import React, { useCallback, useEffect, useState } from 'react'
import Grid from '@mui/material/Grid2';
import { faAngleRight, faChevronLeft, faChevronRight, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import "./knowledge.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { faPenToSquare, faTrashCan } from '@fortawesome/free-regular-svg-icons';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { apiService } from '../../services/apiService';
import { Modal } from 'react-bootstrap';
import { Box } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
function KnowledgeSubCategory() {
    const navigate = useNavigate();

    const [subcategories, setSubCategory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [savesbtnloading, setSavesbtnloading] = useState(false);
    const [selectList, setSelectlist] = useState([]);
    const [paginatedata, setPaginateData] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedSubCategory, setSelectedSubCategory] = useState(null);
    const [name, setName] = useState('');
    const [nameError, setNameError] = useState('');
    const [categoryError, setCategoryError] = useState('');
    const [editMode, setEditMode] = useState(false);
    const [editId, setEditId] = useState(null);
    const [show, setShow] = useState(false);
    const [subCategoryId, setSubCategoryId] = useState(null);
    const handleClose = () => setShow(false);


    const fetchRoots = useCallback(async (params = {}) => {
        setLoading(true);
        try {
            const queryString = new URLSearchParams(params).toString();
            const response = await apiService.getRequest(`/knowledge-article-subcategories?${queryString}`);
            if (response && response.success === true) {
                setSubCategory(response.data);
                setPaginateData(response.meta);
            }
        } catch (error) {
            console.error('Error fetching tab data:', error);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const response = await apiService.getRequest('knowledge-article-categories-list');
            if (response.success) {
                setSelectlist(response.data);
            }
        };
        fetchData();
    }, []);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
        fetchRoots({ page: newPage });
    };

    const handleSearch = () => {
        setCurrentPage(1);
        fetchRoots({ search: searchQuery });
    };

    useEffect(() => {
        fetchRoots();
    }, [fetchRoots]);

    const handleInputChange = (e) => {
        setName(e.target.value);
        if (nameError) {
            setNameError(''); // Clear the name error when the user starts typing
        }
    };

    // Handle select change for category
    const handleSelectChange = (selectedOption) => {
        setSelectedSubCategory(selectedOption);
        if (categoryError) {
            setCategoryError(''); // Clear the category error when the user selects an option
        }
    };
    // Validation for individual fields
    const validateFields = () => {
        let valid = true;

        // Validate name
        if (name.trim() === '') {
            setNameError('Please enter a Sub Category name.');
            valid = false;
        } else {
            setNameError(''); // Clear the name error if valid
        }
        
        if (!selectedSubCategory) {
            setCategoryError('Please select a category.');
            valid = false;
        } else {
            setCategoryError('');
        }

        return valid;
    };
    const handleSubmit = async () => {

        if (!validateFields()) return;

        try {
            setSavesbtnloading(true);
            if (editMode && editId) {
                const response = await apiService.patchRequest(`/knowledge-article-subcategories/${editId}`, {
                    name: name,
                    category_id: selectedSubCategory.value,
                });
                if (response && response.success) {
                    setEditMode(false);
                    setEditId(null);
                    setName('');
                    setSelectedSubCategory(null);
                    fetchRoots();
                }else{
                    setNameError(response && response.message)
                }
            } else {
                const response = await apiService.postRequest('/knowledge-article-subcategories', {
                    name: name,
                    category_id: selectedSubCategory.value,
                });
                if (response && response.success) {
                    setName('');
                    setSelectedSubCategory(null);
                    fetchRoots();
                }else{
                    setNameError(response && response.message)
                }
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setSavesbtnloading(false);
        }
    };
    const handleCancel = () => {
        setName('');
        setSelectedSubCategory(null);
        setEditMode(false);
        setEditId(null);
    };
    const handleEdit = async (id) => {
        try {
            const response = await apiService.getRequest(`/knowledge-article-subcategories/${id}`);
            if (response && response.success) {
                setName(response.data.name);
                const selectedOption = selectList.find(option => option.id === response.data.category_id);
                setSelectedSubCategory({
                    value: selectedOption?.id,
                    label: selectedOption?.name
                });
                setEditMode(true);
                setEditId(id);
            }
        } catch (error) {
            console.error('Error fetching article:', error);
        }
    };

    const handleShow = (rootId) => {
        setSubCategoryId(rootId);
        setShow(true);
    };

    const handleDelete = async () => {
        try {
            await apiService.deleteRequest(`/knowledge-article-subcategories/${subCategoryId}`); // Call DELETE API
            setSubCategory(subcategories.filter(subcategories => subcategories.id !== subCategoryId));
        } catch (err) {
            toast.error("Failed to delete Categories");
        }
        handleClose();
    };

    const rows = subcategories.map((datas,index) => ({
        index:index+1,
        id: datas.id,
        name: datas.name,
        category: datas.category_name,
        knowledgebase: datas.knowledge_base,
        
    }));
    const columns = [
        {
            field: 'index',
            headerName: '#',
            width: 80,
            filterable: true,
        },
        {
            field: 'name',
            headerName: 'Name',
            width: 300,
            filterable: true,
        },
        {
            field: 'category',
            headerName: 'Parent Category',
            width: 320,
            filterable: true,
        },
        {
            field: 'knowledgebase',
            headerName: 'Knowledge Base',
            width: 320,
            filterable: true,
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 200,
            renderCell: (params) => (
                <Grid size={{ xs: 12 }} container alignItems="center" justifyContent="flex-start" className="gap-md-3 h-100 gap-2">
                    <button className='editbtnroot' onClick={() => handleEdit(params.row.id)}>
                        <FontAwesomeIcon icon={faPenToSquare} />
                    </button>
                    <button className='trashbtnroot' onClick={() => handleShow(params.row.id)}>
                        <FontAwesomeIcon icon={faTrashCan} />
                    </button>
                </Grid>
            ),
            filterable: true,
        },
    ];
  return (
    <div className='container-fluid px-0'>
        <div className='content_container'>
            <Grid size={{sm:12}} container justifyContent="space-between" className="main_jobseeker">
                <Grid size={{sm:12}} container alignItems="center">
                    <Grid size={{md:8,xs:12}} container flexDirection="column" className="jobseekerhead">
                        <h3>Sub Category</h3>
                        <span><b onClick={()=>navigate("/dashboard")}>Dashboard</b> <FontAwesomeIcon className='angleright' icon={faAngleRight} /> Manage Knowledge <FontAwesomeIcon className='angleright' icon={faAngleRight} /> Sub Category</span>
                    </Grid>
                </Grid>
                <Grid size={{sm:12}} container justifyContent="space-between" className="flex-md-row-reverse">
                    <Grid size={{md:4,xs:12}} container alignContent="flex-start" alignItems="flex-start" className="my-4 knowledge_baseboxes">
                        <h4>{editMode ? "Edit": "Add"} Sub Category</h4>
                        <Grid size={{xs:12}} container justifyContent="flex-end" className="addlister_base mt-2 pt-2">
                            <label>Sub Category</label>
                            <input
                                placeholder='Name'
                                className='py-4'
                                value={name}
                                onChange={handleInputChange} // Handle input change
                            />
                            {nameError && <p style={{ color: 'red', width: '100%', margin: "0px" }}>{nameError}</p>}
                                <label>Parent Category</label>
                                <Select
                                    className="basic-single w-100 select_withsearch_ka"
                                    classNamePrefix="select"
                                    value={selectedSubCategory}
                                    name="category"
                                    options={selectList.map((option) => ({
                                        label: option.name,
                                        value: option.id,
                                    }))} // Map options for the select box
                                    onChange={handleSelectChange} // Handle select change
                                />
                                {categoryError && <p style={{ color: 'red', width: '100%', margin: "0px" }}>{categoryError}</p>}
                                <button onClick={handleSubmit}>
                                {savesbtnloading ? <img src={require('../../assets/whiteloader.gif')} alt=''/> : <>{editMode ? 'Update' : 'Create'}</>}
                                </button>
                                {editMode && (
                                    <button className='cancel_btneditmode' onClick={handleCancel} style={{ marginLeft: '10px' }}>
                                        Cancel
                                    </button>
                                )}
                        </Grid>
                    </Grid>
                    <Grid size={{md:7.8,xs:12}} container justifyContent="flex-end" className="my-4 knowledge_baseboxes">
                        <h4>Sub Category</h4>
                        <Grid size={{md:8,xs:12}} container justifyContent="space-between" alignItems="center" className="mb-2">
                            <Grid size={{xs:12}} container flexDirection="column">
                                <div className='d-flex jobseekersearch my-3'>
                                    <div className='col-2'>
                                        <FontAwesomeIcon className='iconcol' icon={faMagnifyingGlass} />
                                    </div>
                                    <div className='col-md-7 col-6'>
                                        <input type='text' placeholder='search'
                                            value={searchQuery}
                                            onChange={(e) => setSearchQuery(e.target.value)}
                                        />
                                    </div>
                                    <div className='col-md-3 col-4'>
                                        <button onClick={handleSearch}>Search</button>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                        <div className='w-100'>
                        {loading ? 
                                <div className='d-flex justify-content-center w-100'>
                                    <img style={{ width: "100px" }} src={require('../../assets/pinkloader.gif')} alt='Loading...' />
                                </div> :<>
                                {subcategories && subcategories.length > 0 ? (
                                    <Box sx={{ height: 650, width: '100%' }} className="datatable_jobpagi">
                                        <DataGrid
                                            rows={rows}
                                            columns={columns}
                                            disableSelectionOnClick
                                            components={{ Toolbar: GridToolbar }}
                                        />
                                    </Box>
                                ) : (
                                    <div className="w-100 d-flex justify-content-center mt-4">
                                        <p>No data to display</p>
                                    </div>
                                )}
                            </>}
                        </div>
                        <Grid size={{xs:12}} container justifyContent="center" className="mt-4 pagination_btn">
                            <button className='arrowpaginate'
                                onClick={() => handlePageChange(currentPage - 1)} 
                                disabled={currentPage === 1}>
                                <FontAwesomeIcon icon={faChevronLeft} />
                            </button>
                            {Array.from({ length: paginatedata && paginatedata.last_page }, (_, index) => (
                                <button 
                                    key={index} 
                                    onClick={() => handlePageChange(index + 1)}
                                    className={currentPage === index + 1 ? 'active' : ''}
                                >
                                    {index + 1}
                                </button>
                            ))}
                            <button className='arrowpaginate'
                                onClick={() => handlePageChange(currentPage + 1)} 
                                disabled={currentPage === paginatedata.last_page}>
                                <FontAwesomeIcon icon={faChevronRight} />
                            </button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this Sub Category?</Modal.Body>
                <Modal.Footer>
                    <button className='modal_cancel' onClick={handleClose}>
                        Cancel
                    </button>
                    <button className='modal_delete' onClick={handleDelete}>
                        Delete
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    </div>
  )
}

export default KnowledgeSubCategory
