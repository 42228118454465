import React, { useState } from 'react'
import Grid from '@mui/material/Grid2';
import "./validations.css"
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faBookOpen, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import ValidateCats from '../../../../components/common/ValidateCats';
import { Accordion } from 'react-bootstrap';
// import { toast } from 'react-toastify';
// import { apiService } from '../../../../services/apiService';
function TaskKnowledge() {
    // const { requestId } = useParams();
    // const roles = localStorage.getItem('role');
    const navigate = useNavigate();
    const [isVisible, setIsVisible] = useState(true);
    const toggleVisibility = () => {
        setIsVisible(!isVisible);
      };
      const closeElement = () => {
        setIsVisible(true);
      };
  
  return (
    <div className='container-fluid px-0'>
        <div className={isVisible ? 'hide_mobitr' : 'show_mobitr'}>
            <ValidateCats onClose={closeElement}/>
        </div>
        <div className='content_container_validate' style={{ flexGrow: 1 }}>
            <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead mb-3">
                <span><b onClick={()=>navigate("/dashboard")}>Dashboard</b> <FontAwesomeIcon className='angleright' icon={faAngleRight} /> Case10000</span>
            </Grid>
            <Grid size={{sm:12}} container className="main_jobseeker">
                <Grid size={{sm:12}} container alignItems="center">
                    <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead border_botomer">
                        <h3>I94 Validation</h3>
                        <b>Jobseeker Name</b>
                        <button onClick={toggleVisibility} className='d-md-none main_category_btn'>
                            List
                        </button>
                    </Grid>
                    <Grid size={{xs:12}} container justifyContent="flex-start" className="statusselectbtn mt-4">
                        <div className='knowledgetab_btnredirect'>
                            <button>Overview</button>
                            <button>Assignments</button>
                            <button>Discussions</button>
                            <button className="active_tabbtn">Knowledge</button>
                            <button>History</button>
                        </div>
                    </Grid>
                    <Grid size={{md:12,xs:12}} container justifyContent="space-between" alignItems="center" className="my-4">
                        <h4 className='sideh4searcheq'>Related Knowledge Article</h4>
                        <Grid size={{md:4,xs:12}} container flexDirection="column">
                            <div className='d-flex jobseekersearch my-3'>
                                <div className='col-2'>
                                    <FontAwesomeIcon className='iconcol' icon={faMagnifyingGlass} />
                                </div>
                                <div className='col-7'>
                                    <input type='text' placeholder='search'
                                        // value={searchQuery}
                                        // onChange={(e) => setSearchQuery(e.target.value)}
                                        />
                                </div>
                                <div className='col-3'>
                                    <button>Search</button>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid size={{xs:12}} container className="tast_knowledeaccord">
                    <Accordion className='w-100'>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header><FontAwesomeIcon icon={faBookOpen} className='px-3'/>How to get I94</Accordion.Header>
                            <Accordion.Body>
                                Most people can retrieve their I-94 arrival/departure record online from a U.S. Customs and Border Protection (CBP) website. However, CBP continues to issue paper-based arrival/departure records for some situations.
                                There’s no need to request an I-94. CBP automatically issues the electronic I-94 at entry through an airport or seaport. If you entered through a border crossing, CBP generally issues a paper Form I-94 with some exceptions as described below.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header><FontAwesomeIcon icon={faBookOpen} className='px-3'/>How to get I94</Accordion.Header>
                            <Accordion.Body>
                                Most people can retrieve their I-94 arrival/departure record online from a U.S. Customs and Border Protection (CBP) website. However, CBP continues to issue paper-based arrival/departure records for some situations.
                                There’s no need to request an I-94. CBP automatically issues the electronic I-94 at entry through an airport or seaport. If you entered through a border crossing, CBP generally issues a paper Form I-94 with some exceptions as described below.
                            </Accordion.Body>
                        </Accordion.Item>
                        </Accordion>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    </div>
  )
}

export default TaskKnowledge
