import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid2';
import "./knowledge.css";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { apiService } from '../../services/apiService';
function UnderApprovalHistory() {
    const location = useLocation();
    const currentPath = location.pathname;
    const navigate = useNavigate();
    const [catesNames,setCateNames] = useState('');
    const [articleDetail, setArticleDetail] = useState([]);
    const [articles, setArticles] = useState([]);
    const [loading, setLoading] = useState(true);

    const { id } = useParams();


    useEffect(() => {
        const fetchPreviewData = async () => {
            try {
                const response = await apiService.getRequest(`knowledge-article/${id}`);
                if (response && response.success) {
                    setCateNames(response.data.subcategory);
                    setArticleDetail(response.data);
                }
            } catch (error) {
                console.error('Error fetching article data:', error);
            }
        };

        fetchPreviewData();
    }, [id]);
    useEffect(() => {
        const fetchPreviewData = async () => {
            setLoading(true);
            try {
                const response = await apiService.getRequest(`knowledge-articles/${id}/status-history`);
                if (response && response.success === true && response.data) {
                    setArticles(response.data);
                } else {
                    setArticles([]);
                }
            } catch (error) {
                console.error('Error fetching article data:', error);
                setArticles([]);
            } finally {
                setLoading(false);
            }
        };
        fetchPreviewData();
    }, [id]);
  return (
    <div className='container-fluid px-0'>
        <div className='content_container'>
            <Grid size={{sm:12}} container className="main_jobseeker">
                <Grid size={{sm:12}} container alignItems="center">
                    <Grid size={{sm:12}} container alignItems="center">
                        {articleDetail && articleDetail.status === "under_approval" ? (
                            <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead">
                                <h3>Under Approval</h3>
                                <span>{catesNames.knowledge_base} <FontAwesomeIcon className='angleright' icon={faAngleRight} /> {catesNames.category_name} <FontAwesomeIcon className='angleright' icon={faAngleRight} /> {catesNames.name}</span>
                            </Grid>
                        ):""}
                        {articleDetail && articleDetail.status === "draft" ? (
                            <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead">
                                <h3>Edit Draft</h3>
                                <span>{catesNames.knowledge_base} <FontAwesomeIcon className='angleright' icon={faAngleRight} /> {catesNames.category_name} <FontAwesomeIcon className='angleright' icon={faAngleRight} /> {catesNames.name}</span>
                            </Grid>
                        ):""}
                        {articleDetail && articleDetail.status === "published" ? (
                            <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead">
                                <h3>Published</h3>
                                <span>{catesNames.knowledge_base} <FontAwesomeIcon className='angleright' icon={faAngleRight} /> {catesNames.category_name} <FontAwesomeIcon className='angleright' icon={faAngleRight} /> {catesNames.name}</span>
                            </Grid>
                        ):""}
                        {articleDetail && articleDetail.status === "retired" ? (
                            <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead">
                                <h3>Retired</h3>
                                <span>{catesNames.knowledge_base} <FontAwesomeIcon className='angleright' icon={faAngleRight} /> {catesNames.category_name} <FontAwesomeIcon className='angleright' icon={faAngleRight} /> {catesNames.name}</span>
                            </Grid>
                        ):""}
                    </Grid>
                </Grid>
                <Grid size={{xs:12}} container justifyContent="flex-start" className="statusselectbtn mt-4">
                    <div className='knowledgetab_btnredirect'>
                        <button onClick={() =>{navigate(`/knowledge/edit-article/${id}`)}}>Article</button>
                        <button onClick={() =>{navigate(`/knowledge/under-approval/show/${id}`)}}>Approvals</button>
                        <button className={currentPath.startsWith("/knowledge/under-approval/history") ? "active_tabbtn" : ""} onClick={() =>{navigate(`/knowledge/under-approval/history/${id}`)}}>History</button>
                        <button onClick={() =>{navigate(`/knowledge/attachments/${id}`)}}>Attachments</button>
                        <button onClick={() =>{navigate(`/knowledge/discussions/${id}`)}}>Discussions</button>
                        <button onClick={() =>{navigate(`/knowledge/versions/${id}`)}}>Versions</button>
                    </div>
                </Grid>
                <div className='overtanblehistory w-100'>
                    <Grid size={{xs:12}} container alignContent="flex-start" className="mt-5 tablemin_height">
                        <Grid size={{xs:12}} container className="history_tableheads">
                            <Grid size={{xs:2}} container>
                                <h6>Name</h6>
                            </Grid>
                            <Grid size={{xs:4}} container>
                                <h6>Old Data</h6>
                            </Grid>
                            <Grid size={{xs:4}} container>
                                <h6>New Data</h6>
                            </Grid>
                            <Grid size={{xs:2}} container>
                                <h6>Updated</h6>
                            </Grid>
                        </Grid>
                        {loading ? (
                            <div className='d-flex justify-content-center w-100'>
                                <img style={{ width: "100px" }} src={require('../../assets/pinkloader.gif')} alt='Loading...' />
                            </div>
                        ) : (
                            <>
                                {articles && articles.length > 0 ? ( articles && articles.map((article) => (
                                    <Grid size={{xs:12}} key={article.id} alignItems="center" container className="history_tableans">
                                        <Grid size={{xs:2}} container>
                                            <span>{article.changed_by}</span>
                                        </Grid>
                                        <Grid size={{xs:4}} container flexDirection="column">
                                        {article.changes && (
                                            <>
                                                {article.changes.title && (
                                                    <span>
                                                        <b>Title :</b> {article.changes.title.old || 'N/A'}
                                                    </span>
                                                )}
                                                {article.changes.retired_date && (
                                                    <span>
                                                        <b>Retired date :</b> {article.changes.retired_date.old || 'N/A'}
                                                    </span>
                                                )}
                                                {article.changes.base_id && (
                                                    <span>
                                                        <b>Knowledge Base :</b> {article.changes.base_id.old || 'N/A'}
                                                    </span>
                                                )}
                                                {article.changes.category_id && (
                                                    <span>
                                                        <b>Category ID:</b> {article.changes.category_id.old || 'N/A'}
                                                    </span>
                                                )}
                                                {article.changes.subcategory_id && (
                                                    <span>
                                                        <b>Subcategory ID:</b> {article.changes.subcategory_id.old || 'N/A'}
                                                    </span>
                                                )}
                                                {article.changes.tags && (
                                                    <span>
                                                        <b>Tags:</b> {article.changes.tags.old || 'N/A'}
                                                    </span>
                                                )}
                                                {article.changes.status && (
                                                    <span>
                                                        <b>Status :</b> {article.changes.status.old || 'N/A'}
                                                    </span>
                                                )}
                                            </>
                                        )}
                                        </Grid>
                                        <Grid size={{xs:4}} container flexDirection="column">
                                        {article.changes && (
                                            <>
                                                {article.changes.title && (
                                                    <span>
                                                        <b>Title :</b> {article.changes.title.new || 'N/A'}
                                                    </span>
                                                )}
                                                {article.changes.retired_date && (
                                                    <span>
                                                        <b>Retired date :</b> {article.changes.retired_date.new || 'N/A'}
                                                    </span>
                                                )}
                                                {article.changes.base_id && (
                                                    <span>
                                                        <b>Knowledge Base :</b> {article.changes.base_id.new || 'N/A'}
                                                    </span>
                                                )}
                                                {article.changes.category_id && (
                                                    <span>
                                                        <b>Category ID:</b> {article.changes.category_id.new || 'N/A'}
                                                    </span>
                                                )}
                                                {article.changes.subcategory_id && (
                                                    <span>
                                                        <b>Subcategory ID:</b> {article.changes.subcategory_id.new || 'N/A'}
                                                    </span>
                                                )}
                                                {article.changes.tags && (
                                                    <span>
                                                        <b>Tags:</b> {article.changes.tags.new || 'N/A'}
                                                    </span>
                                                )}
                                                {article.changes.status && (
                                                    <span>
                                                        <b>Status :</b> {article.changes.status.new || 'N/A'}
                                                    </span>
                                                )}
                                            </>
                                        )}
                                        </Grid>
                                        <Grid size={{xs:2}} container>
                                            <span>{article.changed_at}</span>
                                        </Grid>
                                    </Grid>
                                ))
                                ) : (
                                    <div className="w-100 d-flex justify-content-center mt-4">
                                        <p>No data to display</p>
                                    </div>
                                )}
                                </>
                            )}
                    </Grid>
                </div>
            </Grid>
        </div>
    </div>
  )
}

export default UnderApprovalHistory
