import React from 'react'
import Grid from '@mui/material/Grid2';
import { useLocation, useNavigate } from 'react-router-dom';
function TaskTabs() {
    const location = useLocation();
    const navigate = useNavigate()
    const currentPath = location.pathname;
    const roles = localStorage.getItem('role');
  return (
    <Grid size={{xs:12}} container justifyContent="flex-start">
        <div className='admintab_btnredirect'>
            {roles && roles === "admin" ? "":
                <button className={currentPath === "/task/my-request" ? "active_tabbtn" : ""} onClick={() =>{navigate("/task/my-request")}}>My Request</button>
            }
            <button className={currentPath === "/task/my-task" ? "active_tabbtn" : ""} onClick={() =>{navigate("/task/my-task")}}>My Task</button>
        </div>
    </Grid>
  )
}

export default TaskTabs
