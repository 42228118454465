import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid2';
import { useNavigate, useParams } from 'react-router-dom';
import { ResizableBox } from 'react-resizable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { apiService } from '../../../services/apiService';
function ProfileCorrection() {
    const { requestId } = useParams();
    const roles = localStorage.getItem('role');
    const navigate = useNavigate();
    const [isVisible, setIsVisible] = useState(true);
    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };
    const [loading, setLoading] = useState(false);
    const [allResponce, setAllresponce] = useState([]);
    const [changedData, setChangedData] = useState([]);
    const [changedShow, setChangedshow] = useState([]);
    const [profiles, setProfile] = useState([]);
    const [profiles2, setProfile2] = useState([]);
    const [profiles3, setProfile3] = useState([]);
    const [profiles4, setProfile4] = useState([]);
    useEffect(() => {
        const fetchUserData = async () => {
            setLoading(true);
            try {
                const response = await apiService.getRequest(`/request-details/${requestId}`);
                if (response.success === true){
                    setAllresponce(response.request)
                    setProfile(response.job_seeker);
                    setChangedData(response.changed_fields);
                    setChangedshow(response.request.correct_profile_data);
                    if(response.job_seeker.immigration_data && response.job_seeker.immigration_data.length !== 0){
                        setProfile2(response.job_seeker.immigration_data);
                    }
                    if(response.job_seeker.emergency_contact && response.job_seeker.emergency_contact.length !== 0){
                        setProfile3(response.job_seeker.emergency_contact);
                    }
                    if(response.job_seeker.referral_info && response.job_seeker.referral_info.length !== 0){
                        setProfile4(response.job_seeker.referral_info);
                    }
                }else{
                    toast.error("Retry")
                }
            } catch (error) {
                console.error("Error fetching user data", error);
            }finally {
                setLoading(false);
            }
        };
        fetchUserData();
    }, [requestId]);

    const [details, setDetails] = useState('');
  const [error, setError] = useState('');

  const onReject = async () => {
    if (!details.trim()) {
      setError('Please enter Reject reason');
      return;
    }
    setError('');
    const rejectionReason = details;
    try {
      const response = await apiService.postRequest(`/requests/${requestId}/reject`, {
        rejection_reason: rejectionReason,
      });
      if (response && response.success === true) {
        toast("Request rejected successfully!");
        navigate(-1);
      }
    } catch (error) {
      // Handle error - show error message
      console.error("Error rejecting the request:", error);
    }
  };

  const onApprove = async () => {
    try {
      const response = await apiService.postRequest(`/requests/${requestId}/approve`);
      if (response && response.success === true) {
        toast("Request Approved successfully!");
        navigate(-1);
      }
    } catch (error) {
      console.error("Error approving the request:", error);
    }
  };
  
  return (
    <div className='container-fluid px-0'>
        <div className="resizable-container d-flex">
            <div className="d-md-block d-none">
                <ResizableBox
                className="knowledge-category"
                width={400}
                height={Infinity}
                minConstraints={[400, Infinity]}
                maxConstraints={[window.innerWidth - 200, Infinity]}
                axis="x"
                resizeHandles={['e']}
                >
                    <div className="knowledge-category-content">
                        <div className='profiledragers dragging_cats'>
                            <div className='d-flex flex-column'>
                                <h4>Profile Correction</h4>
                                <span className='span_dragers'><img src={require('../../../assets/circlecheck.svg').default} alt=""/>Submit Corrections</span>
                                <span className='span_dragers'><img src={require('../../../assets/circleprocess.svg').default} alt=""/>PRCA Review</span>
                            </div>
                        </div>
                    </div>
                </ResizableBox>
            </div>
            <div className="d-md-none d-block">
                <div className={isVisible !== true ? 'hide_mobitr' : 'show_mobitr'}>
                    {/* <KnowledgeCategory onClose={closeElement}  /> */}
                </div>
            </div>
            <div className='content_container_articals_drag' style={{ flexGrow: 1 }}>
                <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead mb-3">
                    <span><b onClick={()=>navigate("/dashboard")}>Dashboard</b> <FontAwesomeIcon className='angleright' icon={faAngleRight} /> {(roles === 'admin' ? <b onClick={()=>navigate(-1)}>Task</b> : <b onClick={()=>navigate("/task/my-request")}>My Request</b>)}</span>
                </Grid>
                <Grid size={{sm:12}} container className="main_jobseeker" style={{background:"#fff"}}>
                    <Grid size={{sm:12}} container alignItems="center">
                        <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead">
                            <h3>My Request</h3>
                            <b>{profiles.first_name}</b>
                            <button onClick={toggleVisibility} className='d-md-none main_category_btn'>
                                Category
                            </button>
                        </Grid>
                        
                        {loading ? 
                            <div className='d-flex justify-content-center w-100'>
                                <img style={{ width: "100px" }} src={require('../../../assets/pinkloader.gif')} alt='Loading...' />
                            </div> :
                                <Grid size={{md:12,xs:12}} container justifyContent="space-between" className="jobseekers_profilealls my-4">
                                    <Grid size={{xs:12}} container>
                                        <h4>Personal Information</h4>
                                    </Grid>
                                    <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>First Name</span>
                                        <p className={changedData.personal_info && changedData.personal_info.first_name === true ? "changedbackcol" : ""}>{changedData.personal_info && changedData.personal_info.first_name === true ? <>{changedShow.personal_info && changedShow.personal_info.first_name}</>:<>{profiles.first_name}</>}</p>
                                    </Grid>
                                    <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>Last Name</span>
                                        <p className={changedData.personal_info && changedData.personal_info.last_name === true ? "changedbackcol" : ""}>{changedData.personal_info && changedData.personal_info.last_name === true ? <>{changedShow.personal_info && changedShow.personal_info.last_name}</>:<>{profiles.last_name}</>}</p>
                                    </Grid>
                                    <Grid size={{sm:12,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>Email Id</span>
                                        <p>{profiles.email}</p>
                                    </Grid>
                                    <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>Date of Birth</span>
                                        <p className={changedData.personal_info && changedData.personal_info.dob === true ? "changedbackcol" : ""}>{changedData.personal_info && changedData.personal_info.dob === true ? <>{changedShow.personal_info && changedShow.personal_info.dob}</>:<>{profiles.dob}</>}</p>
                                    </Grid>
                                    <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>Gender</span>
                                        <p className={changedData.personal_info && changedData.personal_info.gender === true ? "changedbackcol capssets" : "capssets"}>{changedData.personal_info && changedData.personal_info.gender === true ? <>{changedShow.personal_info && changedShow.personal_info.gender}</>:<>{profiles.gender}</>}</p>
                                    </Grid>
                                    <Grid size={{sm:12,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>Address</span>
                                        <p className={changedData.personal_info && changedData.personal_info.address === true ? "changedbackcol capssets" : "capssets"}>{changedData.personal_info && changedData.personal_info.address === true ? <>{changedShow.personal_info && changedShow.personal_info.address}</>:<>{profiles.address}</>}</p>
                                    </Grid>
                                    <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>City</span>
                                        <p className={changedData.personal_info && changedData.personal_info.city === true ? "changedbackcol capssets" : "capssets"}>{changedData.personal_info && changedData.personal_info.city === true ? <>{changedShow.personal_info && changedShow.personal_info.city}</>:<>{profiles.city}</>}</p>
                                    </Grid>
                                    <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>State</span>
                                        <p className={changedData.personal_info && changedData.personal_info.state === true ? "changedbackcol capssets" : "capssets"}>{changedData.personal_info && changedData.personal_info.state === true ? <>{changedShow.personal_info && changedShow.personal_info.state}</>:<>{profiles.state}</>}</p>
                                    </Grid>
                                    <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>Zip Code</span>
                                        <p className={changedData.personal_info && changedData.personal_info.zipcode === true ? "changedbackcol" : ""}>{changedData.personal_info && changedData.personal_info.zipcode === true ? <>{changedShow.personal_info && changedShow.personal_info.zipcode}</>:<>{profiles.zipcode}</>}</p>
                                    </Grid>
                                    <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>Phone Number</span>
                                        <p className={changedData.personal_info && changedData.personal_info.phone_number === true ? "changedbackcol" : ""}>{changedData.personal_info && changedData.personal_info.phone_number === true ? <>{changedShow.personal_info && changedShow.personal_info.phone_number}</>:<>{profiles.phone_number}</>}</p>
                                    </Grid>
                                    <Grid size={{sm:12,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>Why do you want to enroll with us?</span>
                                        <p className={changedData.personal_info && changedData.personal_info.why_enroll === true ? "changedbackcol capssets" : "capssets"}>{changedData.personal_info && changedData.personal_info.why_enroll === true ? <>{changedShow.personal_info && changedShow.personal_info.why_enroll}</>:<>{profiles.why_enroll}</>}</p>
                                    </Grid>
                                    {profiles2 && profiles2.length === 0 ? 
                                    "":<>
                                    <Grid size={{xs:12}} container>
                                        <h4>Immigration Information</h4>
                                    </Grid>
                                    <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>Citizenship Status</span>
                                        <p className={changedData.immigration_data && changedData.immigration_data.citizenship_status === true ? "changedbackcol capssets" : "capssets"}>{changedData.immigration_data && changedData.immigration_data.citizenship_status === true ? <>{changedShow.immigration_data && changedShow.immigration_data.citizenship_status}</>:<>{profiles2.citizenship_status}</>}</p>
                                    </Grid>
                                    <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>Are you waiting for your EAD Card to Arrive?</span>
                                        <p className={changedData.immigration_data.awaiting_ead_card && changedData.immigration_data.awaiting_ead_card === true ? "changedbackcol capssets" : "capssets"}>{changedData.immigration_data && changedData.immigration_data.awaiting_ead_card === true ? <>{changedShow.immigration_data && changedShow.immigration_data.awaiting_ead_card}</>:<>{profiles2.awaiting_ead_card || "No data"}</>}</p>
                                    </Grid>
                                    <Grid size={{sm:12,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>When did you apply for your EAD Card?</span>
                                        <p className={changedData.emergency_contact && changedData.ead_card_applied_date === true ? "changedbackcol capssets" : "capssets"}>{changedData.immigration_data && changedData.immigration_data.ead_card_applied_date === true ? <>{changedShow.immigration_data && changedShow.immigration_data.ead_card_applied_date}</>:<>{profiles2.ead_card_applied_date || "No data"}</>}</p>
                                    </Grid>
                                    <Grid size={{sm:12,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>Explain your citizenship status</span>
                                        <p className={changedData.immigration_data && changedData.immigration_data.citizenship_explanation === true ? "changedbackcol capssets" : "capssets"}>{changedData.immigration_data && changedData.immigration_data.citizenship_explanation === true ? <>{changedShow.immigration_data && changedShow.immigration_data.citizenship_explanation}</>:<>{profiles2.citizenship_explanation}</>}</p>
                                    </Grid>
                                    <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>Are you legally authorized to work in United States ?</span>
                                        <p className={changedData.immigration_data && changedData.immigration_data.legally_authorized_to_work === true ? "changedbackcol capssets" : "capssets"}>{changedData.immigration_data && changedData.immigration_data.legally_authorized_to_work === true ? <>{changedShow.immigration_data && changedShow.immigration_data.legally_authorized_to_work}</>:<>{profiles2.legally_authorized_to_work}</>}</p>
                                    </Grid>
                                    <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>When will your work authorization expire ?</span>
                                        <p className={changedData.immigration_data && changedData.immigration_data.work_authorization_expiry === true ? "changedbackcol capssets" : "capssets"}>{changedData.immigration_data && changedData.immigration_data.work_authorization_expiry === true ? <>{changedShow.immigration_data && changedShow.immigration_data.work_authorization_expiry}</>:<>{profiles2.work_authorization_expiry || "No data"}</>}</p>
                                    </Grid>
                                    <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>Which year was your SSN Issued ?</span>
                                        <p className={changedData.immigration_data && changedData.immigration_data.ssn_issue_year === true ? "changedbackcol capssets" : "capssets"}>{changedData.immigration_data && changedData.immigration_data.ssn_issue_year === true ? <>{changedShow.immigration_data && changedShow.immigration_data.ssn_issue_year}</>:<>{profiles2.ssn_issue_year || "No data"}</>}</p>
                                    </Grid>
                                    <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>Upload Most Recent I94 Copy</span>
                                        <a className={changedData.immigration_data && changedData.immigration_data.i94_copy === true ? "changedbackcol" : ""} href={`${profiles2.i94_copy}`} target="_blank" rel="noopener noreferrer">View</a>
                                    </Grid>
                                    <Grid size={{sm:12,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>I hereby authorize agents of TekPossible Consulting to access and verify the authenticity of my uploaded I-94 document through the official I-94 website (https://i94.cbp.dhs.gov/I94). I understand that this verification will be conducted in accordance with applicable laws and regulations.</span>
                                        <p>Yes</p>
                                    </Grid>
                                    <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>Upload Proof of Work Authorization</span>
                                        <a className={changedData.immigration_data && changedData.immigration_data.proof_of_work_authorization === true ? "changedbackcol" : ""} href={`${profiles2.proof_of_work_authorization}`} target="_blank" rel="noopener noreferrer">View</a>
                                    </Grid>
                                    <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>Upload Profile Picture</span>
                                        {profiles.profile ? (
                                            <a className={changedData.personal_info && changedData.personal_info.profile === true ? "changedbackcol" : ""} href={`${profiles.profile}`} target="_blank" rel="noopener noreferrer">View</a>
                                            ) : (
                                            <span>No data</span>
                                            )}
                                    </Grid>
                                    </>}
                                    {profiles3 && profiles3.length === 0 ?
                                    "":<>
                                    <Grid size={{sm:12,xs:12}} container>
                                        <h4>Emergency Contact</h4>
                                    </Grid>
                                    <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>First Name</span>
                                        <p className={changedData.emergency_contact && changedData.emergency_contact.first_name === true ? "changedbackcol capssets" : "capssets"}>{changedData.emergency_contact && changedData.emergency_contact.first_name === true ? <>{changedShow.emergency_contact && changedShow.emergency_contact.first_name}</>:<>{profiles3.first_name}</>}</p>
                                    </Grid>
                                    <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>Last Name</span>
                                        <p className={changedData.emergency_contact && changedData.emergency_contact.last_name === true ? "changedbackcol capssets" : "capssets"}>{changedData.emergency_contact && changedData.emergency_contact.last_name === true ? <>{changedShow.emergency_contact && changedShow.emergency_contact.last_name}</>:<>{profiles3.last_name}</>}</p>
                                    </Grid>
                                    <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>Phone Number</span>
                                        <p className={changedData.emergency_contact && changedData.emergency_contact.phone_number === true ? "changedbackcol capssets" : "capssets"}>{changedData.emergency_contact && changedData.emergency_contact.phone_number === true ? <>{changedShow.emergency_contact && changedShow.emergency_contact.phone_number}</>:<>{profiles3.phone_number}</>}</p>
                                    </Grid>
                                    <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>Relationship</span>
                                        <p className={changedData.emergency_contact && changedData.emergency_contact.relation_type === true ? "changedbackcol capssets" : "capssets"}>{changedData.emergency_contact && changedData.emergency_contact.relation_type === true ? <>{changedShow.emergency_contact && changedShow.emergency_contact.relation_type}</>:<>{profiles3.relation_type}</>}</p>
                                    </Grid>
                                    </>}
                                    {profiles4 && profiles4.length === 0 ? 
                                    "":<>
                                    <Grid size={{sm:12,xs:12}} container>
                                        <h4>Referral Information</h4>
                                    </Grid>
                                    <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>Referrer Phone Number</span>
                                        <p className={changedData.referral_info && changedData.referral_info.referral_phone_number === true ? "changedbackcol capssets" : "capssets"}>{changedData.referral_info && changedData.referral_info.referral_phone_number === true ? <>{changedShow.referral_info && changedShow.referral_info.referral_phone_number}</>:<>{profiles4.referral_phone_number}</>}</p>
                                    </Grid>
                                    <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>Referrer Name</span>
                                        <p className={changedData.referral_info && changedData.referral_info.referrer_name === true ? "changedbackcol capssets" : "capssets"}>{changedData.referral_info && changedData.referral_info.referrer_name === true ? <>{changedShow.referral_info && changedShow.referral_info.referrer_name}</>:<>{profiles4.referrer_name}</>}</p>
                                    </Grid>
                                    <Grid size={{sm:12,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>How do you know the referrer ?</span>
                                        <p className={changedData.referral_info && changedData.referral_info.referrer_relationship === true ? "changedbackcol capssets" : "capssets"}>{changedData.referral_info && changedData.referral_info.referrer_relationship === true ? <>{changedShow.referral_info && changedShow.referral_info.referrer_relationship}</>:<>{profiles4.referrer_relationship}</>}</p>
                                    </Grid>
                                    </>}
                                        {/* <button type='submit' className='registersendbtn2'>{submitloader ? <img src={require('../../../assets/whiteloader.gif')} alt=''/> : 'Send Request'}</button> */}
                                    {(roles !== 'job_seeker') && <>
                                        {(allResponce && ((allResponce.status === "Completed") || (allResponce.status === "Rejected"))) ? "" : <>
                                        <Grid size={{sm:12, xs:12}} container flexDirection="column" className="regiter_inputs">
                                        <span>PRCA Comments<span style={{color:"Red"}}> *</span></span>
                                            <textarea placeholder='Describe' rows="10" name="details" value={details} onChange={(e) => setDetails(e.target.value)} />
                                            {error && <p style={{ color: 'red'}}>{error}</p>}
                                        </Grid>
                                        <Grid size={{sm:12, xs:12}} container justifyContent="flex-end" className="mt-2 gap-4">
                                            <button className='registersendbtn1' onClick={onReject}>Reject</button>
                                            <button className='registersendbtn2' onClick={onApprove}>Approve</button>
                                        </Grid></>}
                                    </>}
                                </Grid>
                            }
                    </Grid>
                </Grid>
            </div>
        </div>
    </div>
  )
}

export default ProfileCorrection
