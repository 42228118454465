import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

function ValidateCats({onClose}) {
  return (
    <div>
      <div className='validate_dragers'>
            <div className='d-flex w-100 justify-content-end'>
                <button className='d-md-none closecat_btn' onClick={onClose}><FontAwesomeIcon icon={faXmark} /></button>
            </div>
            <div className='d-flex flex-column'>
                <h4>Signup</h4>
                <span className='span_dragers'><img src={require('../../assets/circlecheck.svg').default} alt=""/>Terms & Conditions</span>
                <span className='span_dragers'><img src={require('../../assets/circleprocess.svg').default} alt=""/>I94 Validation</span>
            </div>
        </div>
    </div>
  )
}

export default ValidateCats
